import { useEffect, useRef, useState } from "react";
import PageLayout from "../../components/PageLayout";
import { useSpeech } from "../../context/SpeechContext";
import { RichAvatar } from "../../components/Avatar/RichAvatar";
import ondulationBgGray from "../../assets/ondulationBgGray.svg";
import cubesBottomRight from "../../assets/cubes_bottom_right.png";
import { useAvatar } from "alpha-ai-avatar-sdk-react";
import ScoreCardFlat from "../FullScore/components/ScoreCardFlat";
import ScoreCard from "../FullScore/components/ScoreCard";
import Window from "../../components/Window";
import { calculateOverallPronunciationScore } from "../../utils/codeFromAzure";
import { cleanString } from "../../utils/string";
import Spinner from "../../components/Spinner";
import { averageGlobalScores } from "../FullScore/helpers";
import phrases from "../../mock/phrases";
import { useLesson } from "../../context/LessonContext";
import useSpeechErrors from "../../hooks/useSpeechErrors";
import PageControl from "./components/PageControl";
import GoHomeButton from "../../components/GoHomeButton";

function Score() {
  const queryString = location.search;
  const { analyzedWordsWithErrors } = useSpeech();
  const [loading, setLoading] = useState(true);
  const { lessonPhrase } = useLesson();
  const [result, setResult] = useState<any>([]);
  const totalPages = 2;
  const [scorePageStep, setScorePageStep] = useState(0);
  const allPhrasesCombined = phrases.join(" ");
  const { countErrors } = useSpeechErrors();

  const goHome = () => {
    window.location.href = `/${queryString}`;
  };

  // Redirect to home if user didn't come to this route from a assessment
  useEffect(() => {
    if (!lessonPhrase) {
      goHome();
    }
  }, []);

  useEffect(() => {
    if (analyzedWordsWithErrors.words.length !== 0) {
      const result = calculateOverallPronunciationScore({
        allWords: structuredClone(analyzedWordsWithErrors.words),
        currentText: structuredClone(analyzedWordsWithErrors.words).map(
          (item: any) => item.Word
        ),
        language: "en-AU",
        reference_text: cleanString(allPhrasesCombined),
      });

      setResult(result);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [analyzedWordsWithErrors.words]);

  const { isConnected, say } = useAvatar();
  const isInitialized = useRef(false);

  const avatarMessage =
    "Congratulations, you completed the lesson! I knew you could do it! Click home and return to the main menu.";

  useEffect(() => {
    if (isConnected && !isInitialized.current) {
      isInitialized.current = true;
      // @TODO setTimeout is needed to ensure `say` works consistently - it fails a lot of the times even though isConnected = true
      setTimeout(() => {
        say(avatarMessage);
      }, 300);
    }
  }, [isConnected]);

  const onNextPage = () => {
    if (scorePageStep + 1 > totalPages - 1) return;
    setScorePageStep(scorePageStep + 1);
  };

  const onPreviousPage = () => {
    if (scorePageStep - 1 < 0) return;
    setScorePageStep(scorePageStep - 1);
  };

  const onClickNode = (index: number) => {
    setScorePageStep(index);
  };

  if (loading || !result || !result.length) {
    return (
      <PageLayout className="items-center justify-center h-[100vh]">
        <Spinner className="!w-[100px] !h-[100px]" />
      </PageLayout>
    );
  }

  const topTenWorstPhonemes = getTopTenWorstPhonemes(result);

  const { accuracyScore, fluencyScore } = averageGlobalScores(
    analyzedWordsWithErrors.scores
  );

  const totalOmissions = result.filter(
    (item: any) => item.PronunciationAssessment.ErrorType === "Omission"
  ).length;

  const totalWordsInOriginalPhrase = allPhrasesCombined.split(" ").length;

  const completenessScore = Math.floor(
    ((totalWordsInOriginalPhrase - totalOmissions) /
      totalWordsInOriginalPhrase) *
      100
  );

  const overallScore = (accuracyScore + completenessScore + fluencyScore) / 3;
  const errorsCount = countErrors(result);

  return (
    <div
      style={{
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right bottom",
        backgroundImage: `url(${cubesBottomRight}), url(${ondulationBgGray})`,
      }}
    >
      <PageLayout className="w-[100vw]">
        <Window noPadding className="min-w-[50%] mb-8 pb-2 px-4 mt-4">
          {scorePageStep === 0 && (
            <>
              <div className="min-h-[400px] min-w-[800px] w-full text-black flex justify-center text-center my-4 flex-col">
                <div className="flex justify-between ml-[50px]">
                  <div className="w-full">
                    <ScoreCard
                      label="Overall Score"
                      score={Math.floor(overallScore)}
                    />
                    <div className="grid grid-cols-4 gap-2 mt-4">
                      <ScoreCardFlat
                        label="Accuracy"
                        score={Math.floor(accuracyScore)}
                      />
                      <ScoreCardFlat
                        label="Fluency"
                        score={Math.floor(fluencyScore)}
                      />
                      <ScoreCardFlat
                        label="Completeness"
                        score={Math.floor(completenessScore)}
                      />
                      <ScoreCardFlat
                        label="Errors"
                        score={Math.floor(errorsCount)}
                      />
                    </div>
                  </div>
                  <div className="w-16" />
                </div>
              </div>
              <div className="relative flex justify-center items-center mb-8">
                <PageControl
                  currentPage={scorePageStep}
                  totalPages={totalPages}
                  onNext={onNextPage}
                  onPrev={onPreviousPage}
                  onNodeClick={onClickNode}
                />
                <div className="absolute right-2 z-50">
                  <GoHomeButton />
                </div>
              </div>
            </>
          )}
          {scorePageStep === 1 && (
            <>
              <div className="min-h-[400px] min-w-[800px] w-full text-black flex justify-center text-center my-4 flex-col">
                <div className="flex justify-between ml-[50px]">
                  <div className="w-full">
                    <h1 className="text-[40px] font-bold text-white mb-8">
                      Phonemes to improve
                    </h1>
                    <div className={`grid grid-cols-5 gap-2 mt-4`}>
                      {topTenWorstPhonemes.map((item: any, index: number) => (
                        <ScoreCardFlat
                          key={"phoneme-" + index}
                          className="w-[128px] h-[112px]"
                          score={item.phoneme}
                          label={
                            Math.floor(item.accuracyScore) < 10
                              ? "< 10"
                              : Math.floor(item.accuracyScore).toString()
                          }
                        />
                      ))}
                    </div>
                  </div>
                  <div className="w-16" />
                </div>
              </div>
              <div className="relative flex justify-center items-center mb-8">
                <PageControl
                  currentPage={scorePageStep}
                  totalPages={totalPages}
                  onNext={onNextPage}
                  onPrev={onPreviousPage}
                  onNodeClick={onClickNode}
                />
                <div className="absolute right-2 z-50">
                  <GoHomeButton />
                </div>
              </div>
            </>
          )}
        </Window>

        <div className="mt-8 mb-4">
          <RichAvatar message={avatarMessage} />
        </div>
      </PageLayout>
    </div>
  );
}

export default Score;

function getTopTenWorstPhonemes(data: any) {
  const phonemeMap = new Map();

  data.forEach((word: any) => {
    // Ensure Phonemes exists and is an array
    if (Array.isArray(word.Phonemes)) {
      word.Phonemes.forEach((phoneme: any) => {
        // Ensure PronunciationAssessment and AccuracyScore exist
        if (phoneme && phoneme.PronunciationAssessment) {
          const phonemeKey = phoneme.Phoneme;
          const phonemeScore = phoneme.PronunciationAssessment.AccuracyScore;

          // If the phoneme is already in the map, update if the new score is worse
          if (phonemeMap.has(phonemeKey)) {
            const existingScore = phonemeMap.get(phonemeKey).accuracyScore;
            if (phonemeScore < existingScore) {
              phonemeMap.set(phonemeKey, {
                phoneme: phonemeKey,
                accuracyScore: phonemeScore,
                word: word.Word,
              });
            }
          } else {
            phonemeMap.set(phonemeKey, {
              phoneme: phonemeKey,
              accuracyScore: phonemeScore,
              word: word.Word,
            });
          }
        }
      });
    }
  });

  const phonemeScores = Array.from(phonemeMap.values())
    .sort((a, b) => a.accuracyScore - b.accuracyScore)
    .filter((item) => item.accuracyScore < 100);

  return phonemeScores.slice(0, 10);
}
