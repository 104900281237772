import PageLayout from "../../components/PageLayout";
import PhraseVisualizer from "../../components/PhraseVisualizer";
import { useEffect, useRef, useState } from "react";
import storiesMock from "../../mock/stories";
import { useSpeech } from "../../context/SpeechContext";
import Window from "../../components/Window";
import { RichAvatar } from "../../components/Avatar/RichAvatar";
import { useAvatar } from "alpha-ai-avatar-sdk-react";
import Spinner from "../../components/Spinner";
import ondulationBgGray from "../../assets/ondulationBgGray.svg";
import cubesBottomRight from "../../assets/cubes_bottom_right.png";
import { useLesson } from "../../context/LessonContext";
import { useNavigate, useLocation } from "react-router-dom";
import { cleanString } from "../../utils/string";
import MicButton from "../MicButton";

function Assessment() {
  const location = useLocation();
  const queryString = location.search;
  const params = new URLSearchParams(queryString);
  const amiraText = params.get("amiraText");
  const skipIntro =
    params.get("skipIntro") && params.get("skipIntro") === "true";

  const [micButtonIsEnabled, setMicButtonIsEnabled] = useState(skipIntro);
  const [showLoadButton, setShowLoadButton] = useState(false);

  const navigate = useNavigate();
  const storyIndex = amiraText ? 1 : 0;
  const phrase = storiesMock[storyIndex];

  const cleanPhrase = cleanString(phrase);

  const {
    setCurrentPhrase,
    handleStopRecording,
    nextWord,
    nextWordIndex,
    setNextWord,
    recording,
    timer,
    remainingWordsRef,
  } = useSpeech();

  const { lessonPhrase, setLessonPhrase, setRemainingLessonPhrase } =
    useLesson();

  useEffect(() => {
    if (recording) {
      timer.startTimer();
    }
  }, [recording]);

  useEffect(() => {
    setLessonPhrase(cleanPhrase.toLowerCase());
    setRemainingLessonPhrase(cleanPhrase.toLowerCase());
    setNextWord(cleanPhrase.split(" ")[0]);
    setTimeout(() => {
      setShowLoadButton(true);
    }, 6000);
  }, []);

  useEffect(() => {
    if (!lessonPhrase) return;
    setCurrentPhrase(lessonPhrase);
  }, [lessonPhrase]);

  useEffect(() => {
    const totalWords = lessonPhrase.split(" ").length;

    if (nextWordIndex === totalWords - 1 && lessonPhrase?.length > 0) {
      timer.stopTimer();
      handleStopRecording();
      navigate(`/full-score${queryString}`);
      return;
    }
  }, [nextWord, remainingWordsRef.current]);

  const { isConnected, say } = useAvatar();
  const isInitialized = useRef(false);

  useEffect(() => {
    if (skipIntro) return;
    if (isConnected && !isInitialized.current) {
      isInitialized.current = true;
      // @TODO setTimeout is needed to ensure `say` works consistently - it fails a lot of the times even though isConnected = true
      setTimeout(() => {
        say(
          "Hi, we're going to do a reading assessment." +
            " That just means I want you to read to me the best you can." +
            " I'm going to give you a story to read on the screen, and I want you to read it to me." +
            // " If you get stuck, it's okay, I'll tell you the word so you can keep reading. " +
            " Here we go, I'm excited to hear you read this story!" +
            "Now click the start button and begin reading."
        );
      }, 500);
      setTimeout(() => {
        setMicButtonIsEnabled(true);
      }, 17000);
    }
  }, [isConnected]);

  return (
    <div
      style={{
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right bottom",
        backgroundImage: `url(${cubesBottomRight}), url(${ondulationBgGray})`,
      }}
    >
      <PageLayout className="p-20">
        {!isConnected ? (
          <div className="mt-12">
            {showLoadButton ? (
              <button
                onClick={() => {
                  setShowLoadButton(false);
                }}
                className="mt-4 border-none w-fit rounded-full border-[2px] text-white font-bold text-2xl bg-gradient-to-b from-[#00D0FF] to-[#0067F5] bg-opacity-30 shadow-xl"
              >
                Click to Start Lesson!
              </button>
            ) : (
              <Spinner className="!w-[100px] !h-[100px]" />
            )}
          </div>
        ) : (
          <>
            <Window noPadding className="w-[690px] py-6 px-8 mb-4">
              <div className="flex">
                <div className="mr-8 w-[125px] h-[129px] border-t-[2px] border-r-[2px] border-b-[16px] border-l-[2px] border-[#3800D7] rounded-full">
                  <RichAvatar size="120" message={phrase} />
                </div>
                <div className="w-full flex flex-col">
                  <div className="flex justify-between align-center h-full">
                    <div className="self-center h-[48px] flex justify-center items-center min-w-[136px] text-white text-xl font-bold rounded-xl border border-white/90 bg-white/5">
                      <span className="">Story</span>
                    </div>
                    <MicButton micButtonIsEnabled={!!micButtonIsEnabled} />
                  </div>
                </div>
              </div>
            </Window>
            <Window
              noPadding
              className="p-8 bg-white-gradient border-semi-white w-[690px] h-[80%] justify-center items-center"
            >
              <PhraseVisualizer
                micButtonIsEnabled={micButtonIsEnabled}
                width="800"
                lessonPhrase={phrase}
                type="assessment"
              />
            </Window>
          </>
        )}
      </PageLayout>
    </div>
  );
}

export default Assessment;
