import lodash from "lodash";
import difflib from "difflib";

export function calculateOverallPronunciationScore({
  allWords,
  currentText = [],
  language,
  reference_text,
}: any) {
  const scoreNumber: any = {
    accuracyScore: 0,
    fluencyScore: 0,
    compScore: 0,
    prosodyScore: 0,
  };

  var startOffset = 0;
  var recognizedWords: any[] = [];
  var fluencyScores: any[] = [];
  var prosodyScores: any[] = [];
  var durations: any[] = [];
  var jo: any = {};
  const resText = currentText.join(" ");
  let wholelyricsArry = [];
  let resTextArray = [];

  let resTextProcessed = (resText.toLocaleLowerCase() ?? "")
    .replace(new RegExp('[!"#$%&()*+,-./:;<=>?@[^_`{|}~]+', "g"), "")
    .replace(new RegExp("]+", "g"), "");
  let wholelyrics = (reference_text.toLocaleLowerCase() ?? "")
    .replace(new RegExp('[!"#$%&()*+,-./:;<=>?@[^_`{|}~]+', "g"), "")
    .replace(new RegExp("]+", "g"), "");
  wholelyricsArry = wholelyrics.split(" ");
  resTextArray = resTextProcessed.split(" ");

  const wholelyricsArryRes = lodash.map(
    lodash.filter(wholelyricsArry, (item) => !!item),
    (item) => item.trim()
  );

  // For continuous pronunciation assessment mode, the service won't return the words with `Insertion` or `Omission`
  // We need to compare with the reference text after received all recognized words to get these error words.
  const diff = new difflib.SequenceMatcher(
    null,
    wholelyricsArryRes,
    resTextArray
  );

  const lastWords = [];
  for (const d of diff.getOpcodes()) {
    if (d[0] == "insert" || d[0] == "replace") {
      for (let j = d[3]; j < d[4]; j++) {
        if (
          allWords &&
          allWords.length > 0 &&
          allWords[j].PronunciationAssessment.ErrorType !== "Insertion"
        ) {
          allWords[j].PronunciationAssessment.ErrorType = "Insertion";
        }
        lastWords.push(allWords[j]);
      }
    }

    if (d[0] == "delete" || d[0] == "replace") {
      if (
        d[2] == wholelyricsArryRes.length &&
        !(jo.RecognitionStatus == "Success" || jo.RecognitionStatus == "Failed")
      )
        continue;
      for (let i = d[1]; i < d[2]; i++) {
        const word = {
          Word: wholelyricsArryRes[i],
          PronunciationAssessment: {
            ErrorType: "Omission",
          },
        };
        lastWords.push(word);
      }
    }
    if (d[0] == "equal") {
      for (let k = d[3], count = 0; k < d[4]; count++) {
        if (["zh-cn"].includes(language.toLowerCase())) {
          let len = 0;
          let bfind = false;
          lodash.map(allWords, (item, index) => {
            if (len >= k && !bfind) {
              if (
                allWords[index].PronunciationAssessment.ErrorType !== "None"
              ) {
                allWords[index].PronunciationAssessment.ErrorType = "None";
              }
              lastWords.push(allWords[index]);
              bfind = true;
              k += allWords[index].Word.length;
            }
            len = len + item.Word.length;
          });
        } else {
          lastWords.push(allWords[k]);
          k++;
        }
      }
    }
  }

  let reference_words = [];
  reference_words = wholelyricsArryRes;

  let recognizedWordsRes = [];
  lodash.forEach(recognizedWords, (word) => {
    if (word.PronunciationAssessment.ErrorType == "None") {
      recognizedWordsRes.push(word);
    }
  });

  let compScore = Number(
    ((recognizedWordsRes.length / reference_words.length) * 100).toFixed(0)
  );
  if (compScore > 100) {
    compScore = 100;
  }
  scoreNumber.compScore = compScore;

  const accuracyScores: any[] = [];
  lodash.forEach(lastWords, (word) => {
    if (word && word?.PronunciationAssessment?.ErrorType != "Insertion") {
      accuracyScores.push(
        Number(word?.PronunciationAssessment.AccuracyScore ?? 0)
      );
    }
  });
  scoreNumber.accuracyScore = Number(
    (lodash.sum(accuracyScores) / accuracyScores.length).toFixed(0)
  );

  if (startOffset) {
    const sumRes: any[] = [];
    lodash.forEach(fluencyScores, (x, index) => {
      sumRes.push(x * durations[index]);
    });
    scoreNumber.fluencyScore = lodash.sum(sumRes) / lodash.sum(durations);
  }
  scoreNumber.prosodyScore = Number(
    (lodash.sum(prosodyScores) / prosodyScores.length).toFixed(0)
  );

  const sortScore = Object.keys(scoreNumber).sort(function (a, b) {
    return scoreNumber[a] - scoreNumber[b];
  });
  if (jo.RecognitionStatus == "Success" || jo.RecognitionStatus == "Failed") {
    scoreNumber.pronScore = (
      scoreNumber[sortScore["0"]] * 0.4 +
      scoreNumber[sortScore["1"]] * 0.2 +
      scoreNumber[sortScore["2"]] * 0.2 +
      scoreNumber[sortScore["3"]] * 0.2
    ).toFixed(0);
  } else {
    scoreNumber.pronScore = Number(
      (
        scoreNumber.accuracyScore * 0.5 +
        scoreNumber.fluencyScore * 0.5
      ).toFixed(0)
    );
  }

  return lastWords.map((item: any) => {
    if (item.PronunciationAssessment.ErrorType === "Insertion") {
      item.PronunciationAssessment = {
        AccuracyScore: 0,
        ErrorType: "Insertion",
      };
    }
    return item;
  });
}
