import React, { createContext, useState, ReactNode } from "react";

interface AudioContextType {
  audioUrl: string | null;
  setAudioUrl: (url: string | null) => void;
}

const AudioContext = createContext<AudioContextType | undefined>(undefined);

interface AudioProviderProps {
  children: ReactNode;
}

export const AudioProvider: React.FC<AudioProviderProps> = ({ children }) => {
  const [audioUrl, setAudioUrl] = useState<string | null>(null);

  return (
    <AudioContext.Provider value={{ audioUrl, setAudioUrl }}>
      {children}
    </AudioContext.Provider>
  );
};

export const useAudio = (): AudioContextType => {
  const context = React.useContext(AudioContext);
  if (context === undefined) {
    throw new Error("useAudio must be used within an AudioProvider");
  }
  return context;
};
