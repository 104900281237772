import {
  InterventionStatus,
  InterventionType,
} from "../../context/InterventionsContext/InterventionsContext";
import Paginator from "../Paginator";

function InterventionsList({ interventions }: any) {
  if (interventions.length === 0) return;

  const filteredInterventions = interventions.filter((item: any) => {
    return (
      item.type !== InterventionType.UNKNOWN &&
      item.status !== InterventionStatus.COMPLETED
    );
  });

  if (filteredInterventions.length === 0) return;

  return (
    <div className="w-full flex justify-center">
      <Paginator items={filteredInterventions} showControls={false} />
    </div>
  );
}

export default InterventionsList;
