import homeIconSvg from "../../assets/homeIcon.svg";

function GoHomeButton() {
  const queryString = location.search;
  const goHome = () => {
    window.location.href = `/${queryString}`;
  };

  return (
    <div
      className="w-[160px] h-[64px] px-8 py-4 mt-4 border-none w-fit rounded-full border-[2px] text-white font-bold text-2xl bg-gradient-to-b from-[#00D0FF] to-[#0067F5] bg-opacity-30 shadow-xl flex justify-center items-center cursor-pointer"
      onClick={goHome}
    >
      <img src={homeIconSvg} className="mr-2" />
      <div>Home</div>
    </div>
  );
}

export default GoHomeButton;
