import { useEffect, useState } from "react";
import {
  IIntervention,
  InterventionStatus,
  useInterventions,
} from "../../context/InterventionsContext/InterventionsContext";
import Spinner from "../Spinner";
import { shuffleArray } from "../../utils/array";
import { RichAvatar } from "../Avatar/RichAvatar";
import { useAvatar } from "alpha-ai-avatar-sdk-react";
import { useConversation } from "../../hooks/useConversation";
import { useSpeech } from "../../context/SpeechContext";
import Window from "../Window";
import { getChatCompletion } from "../../utils/api";
import { successMessage } from "../../utils/avatarPhrases";
import StepsProgressBar from "../StepsProgressBar";
import MicButton from "../MicButton";
import phrasesMock from "../../mock/phrases";
import { useLesson } from "../../context/LessonContext";

interface IWord {
  word: string;
  rhymes: boolean;
}

const DISABLED_BUTTON_CSS =
  "disabled:opacity-50 disabled:cursor-not-allowed disabled:select-none";
const DEFAULT_SELECTED_WORD = {
  word: "",
  rhymes: false,
};

function RhymeIntervention({ id }: { id: string }) {
  const totalLessons = phrasesMock.length - 1;
  const { interventions, setInterventions } = useInterventions();
  const [disableOptions, setDisableOptions] = useState(false);
  const [interventionLoaded, setInterventionLoaded] = useState(false);
  const [wordsList, setWordsList] = useState<any>([]);
  const [selectedWord, setSelectedWord] = useState<IWord>(
    DEFAULT_SELECTED_WORD
  );
  const { setTempFlag } = useSpeech();
  const { lessonStep, blockUserSpeech, setBlockUserSpeech } = useLesson();

  const currentIntervention = interventions.find(
    (intervention: IIntervention) => {
      return intervention.id === id;
    }
  )!;

  const { word } = currentIntervention;

  const updateInterventions = () => {
    const updatedInterventions = interventions.map((intervention: any) => {
      if (intervention.id === id) {
        return {
          ...intervention,
          status: InterventionStatus.COMPLETED,
        };
      }
      return intervention;
    });
    setInterventions([...updatedInterventions]);
  };
  const { isAvatarSpeaking, say, stop } = useAvatar();

  const avatarMessage = `What rhymes with ${word}? Click the box with the correct word`;
  const { cancelConversation, ignoreNext } = useConversation(
    {
      queue: [
        () => {
          say(avatarMessage, { prosody: { rate: "-10%" } });
        },
      ],
    },
    currentIntervention.word
  );

  useEffect(() => {
    setSelectedWord(DEFAULT_SELECTED_WORD);
  }, [currentIntervention?.id]);

  useEffect(() => {
    if (!interventionLoaded) {
      setInterventionLoaded(true);
      return;
    }
    setBlockUserSpeech(true);
    setWordsList([]);
    Promise.all([
      getChatCompletion(
        `What's a perfect rhyme for the word "${word}? It must have the same ending sound, preferably with the same letters at the end. Provide it in the JSON format { word: string }.`
        // `For the reference word "${word}", return a perfect rhyme in the JSON format { word: string }. They must have the same ending sound, it must be a simple and perfect rhyme.`
      ),
      getChatCompletion(
        `What are two words that do NOT rhyme with the word "${word}"? They must have a completely different ending sound, with different letters at the end. Provide it in the JSON format { words: [string, string] }`
        // `For the reference word "${word}", return two words that do NOT rhyme with the reference word in the JSON format { words: [string, string] }. Avoid the same letters in the last syllable`
      ),
    ])
      .then(([responseRhyme, responseNotRhyme]) => {
        const { word: rhymeWord } = JSON.parse(
          responseRhyme.data.choices[0].message.content
        );
        const { words: notRhymeWords } = JSON.parse(
          responseNotRhyme.data.choices[0].message.content
        );
        setWordsList(
          shuffleArray([
            { word: rhymeWord, rhymes: true },
            ...notRhymeWords.map((word: string) => ({ word, rhymes: false })),
          ])
        );
      })
      .catch((error) => {
        console.error("error on rhyme intervention", error);
      });
  }, [interventionLoaded, id]);

  const onFinished = () => {
    cancelConversation();
    say(successMessage());
    setTimeout(() => {
      setBlockUserSpeech(false);
      updateInterventions();
    }, 2000);
  };

  const onAnswerChange = (answer: IWord) => {
    if (!answer) {
      return;
    }

    stop();
    setSelectedWord(answer);
    if (answer.rhymes) {
      // cancelIgnore();
      setTempFlag(false);
      onFinished();
    } else {
      setDisableOptions(true);
      setTempFlag(true);
      ignoreNext();
      say("Try a different word");
      setTimeout(() => {
        setDisableOptions(false);
      }, 1000);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <Window noPadding className="w-[690px] py-6 px-8 mt-4">
        <div className="flex">
          <div className="mr-8 w-[125px] h-[129px] border-t-[2px] border-r-[2px] border-b-[16px] border-l-[2px] border-[#3800D7] rounded-full">
            <RichAvatar size="120" message={word} />
          </div>
          <div className="w-full flex flex-col">
            <StepsProgressBar
              steps={totalLessons + 1}
              currentStep={lessonStep}
            />

            <div className="flex justify-between align-center h-full">
              <div className="self-center h-[48px] flex justify-center items-center min-w-[136px] text-white text-xl font-bold rounded-xl border border-white/90 bg-white/5">
                <span className="">Practice</span>
              </div>
              <MicButton
                blockUserSpeech={blockUserSpeech}
                micButtonIsEnabled={true}
              />
            </div>
          </div>
        </div>
      </Window>
      <Window
        noPadding
        className="py-6 px-8 mt-4 bg-white-gradient border-semi-white w-[690px] h-full justify-center items-center"
      >
        <div className="flex justify-center flex-col items-center w-full">
          <div className="font-semibold text-4xl text-semi-black mt-10 mb-10">
            What rhymes with <span className="text-[#6D09FC]">{word}</span>?
          </div>
          <div className="flex">
            {wordsList.length === 0 ? (
              <div className="h-[82px]">
                <Spinner />
              </div>
            ) : (
              wordsList.map((item: IWord) => {
                const isSelected = selectedWord.word === item.word;
                const bg = () => {
                  if (isSelected) {
                    if (item.rhymes) {
                      return "bg-green-400";
                    } else {
                      return "bg-red-400";
                    }
                  }

                  return "bg-white text-gray-800";
                };

                return (
                  <button
                    className={`
                  rounded-[24px] border-t-[2px] border-r-[2px] border-b-[16px] border-l-[2px] border-[#0135c3] bg-gradient-to-b from-[#00D0FF] to-[#0067F5] drop-shadow-2xl h-[90px] min-w-[140px] mx-4 text-white font-bold text-2xl
                  ${bg()}  
                  ${selectedWord === item ? "" : DISABLED_BUTTON_CSS}`}
                    onClick={() => {
                      onAnswerChange(item);
                    }}
                    key={item.word}
                    disabled={
                      isAvatarSpeaking || selectedWord.rhymes || disableOptions
                    }
                  >
                    {item.word}
                  </button>
                );
              })
            )}
          </div>
        </div>
      </Window>
    </div>
  );
}

export default RhymeIntervention;
