import { useEffect, useState } from "react";
import { useSpeech } from "../../context/SpeechContext";
import {
  IIntervention,
  InterventionStatus,
  useInterventions,
} from "../../context/InterventionsContext/InterventionsContext";
import checkCheckPng from "../../assets/check-check.png";
import {
  breakInBetween,
  formatSpellOut,
  wrapPhoneme,
} from "../../utils/avatarMessage";
import phrasesMock from "../../mock/phrases";
import { RichAvatar } from "../Avatar/RichAvatar";
import { useConversation } from "../../hooks/useConversation";
import { useAvatar } from "alpha-ai-avatar-sdk-react";
import Window from "../Window";
import MicButton from "../MicButton";
import StepsProgressBar from "../StepsProgressBar";
import { useLesson } from "../../context/LessonContext";

export enum SpellOutBy {
  // WORD = "word",
  PHONEME = "phoneme",
  SYLLABLE = "syllable",
  GRAPHEME = "grapheme",
  LETTER = "letter",
}

function SpellOutIntervention({
  id,
  type = SpellOutBy.SYLLABLE,
}: {
  id: string;
  type?: SpellOutBy;
}) {
  const totalLessons = phrasesMock.length - 1;
  const [interventionLoaded, setInterventionLoaded] = useState(false);
  const { interventions, setInterventions } = useInterventions();
  const [showCompletedMessage, setShowCompletedMessage] = useState(false);
  const { lessonStep, blockUserSpeech, setBlockUserSpeech } = useLesson();
  const { currentSpeechResult, setTempFlag } = useSpeech();
  const currentIntervention = interventions.find(
    (intervention: IIntervention) => {
      return intervention.id === id;
    }
  )!;

  const updateInterventions = () => {
    const updatedInterventions = interventions.map((intervention: any) => {
      if (intervention.id === id) {
        return {
          ...intervention,
          status: InterventionStatus.COMPLETED,
        };
      }
      return intervention;
    });
    setInterventions([...updatedInterventions]);
  };

  const { word, phonemes, graphemes, syllables } = currentIntervention;

  const phrase = () => {
    switch (type) {
      case SpellOutBy.PHONEME:
        return formatSpellOut(
          phonemes.split(" ").map((word) => wrapPhoneme(word))
        );
      case SpellOutBy.GRAPHEME:
        return formatSpellOut(
          graphemes.split(" ").map((word) => wrapPhoneme(word))
        );
      case SpellOutBy.SYLLABLE:
        return formatSpellOut(
          syllables.split(" ").map((word) => wrapPhoneme(word))
        );
      case SpellOutBy.LETTER:
        return formatSpellOut(
          word.replace("'", "").split("")
          // .map((letter) => `"${letter}"`)
        );
      default:
        return word;
    }
  };

  const { say } = useAvatar();
  const avatarMessage = breakInBetween(
    [
      "Let's look at this word. Here's how it's spelled",
      `${phrase()}, is pronounced ${word}`,
    ],
    150
  );
  const { cancelConversation } = useConversation(
    {
      queue: [
        () => {
          setBlockUserSpeech(true);
          say(avatarMessage, { prosody: { rate: "-10%" } });
        },
        () => {
          say(`Now you try to say ${word}`, { prosody: { rate: "-10%" } });
        },
        () => {
          setBlockUserSpeech(false);
        },
      ],
    },
    currentIntervention.word
  );

  useEffect(() => {
    if (!interventionLoaded) {
      setInterventionLoaded(true);
      return;
    }

    const gotWordRightAtLeastOnce =
      currentSpeechResult.length > 0 &&
      currentSpeechResult.some(
        (result: any) =>
          result.PronunciationAssessment.ErrorType !== "Mispronunciation" &&
          result.PronunciationAssessment.AccuracyScore >= 80
      );

    if (gotWordRightAtLeastOnce) {
      setShowCompletedMessage(true);
      setTempFlag(false);
      cancelConversation();
      say("Nice work!");
      setTimeout(() => {
        onFinished();
      }, 2000);
    } else {
      if (!blockUserSpeech) {
        say(`Not quite. Let's try again`);
      }
    }
  }, [currentSpeechResult]);

  const onFinished = () => {
    setShowCompletedMessage(false);
    updateInterventions();
  };

  return (
    <div className="flex flex-col">
      <Window noPadding className="w-[690px] py-6 px-8 mt-4">
        <div className="flex">
          <div className="mr-8 w-[125px] h-[129px] border-t-[2px] border-r-[2px] border-b-[16px] border-l-[2px] border-[#3800D7] rounded-full">
            <RichAvatar size="120" message={avatarMessage} />
          </div>
          <div className="w-full flex flex-col">
            <StepsProgressBar
              steps={totalLessons + 1}
              currentStep={lessonStep}
            />

            <div className="flex justify-between align-center h-full">
              <div className="self-center h-[48px] flex justify-center items-center min-w-[136px] text-white text-xl font-bold rounded-xl border border-white/90 bg-white/5">
                <span className="">Practice</span>
              </div>
              <MicButton
                blockUserSpeech={blockUserSpeech}
                micButtonIsEnabled={true}
              />
            </div>
          </div>
        </div>
      </Window>
      <Window
        className={`mt-4 w-[690px] h-full justify-center items-center ${
          showCompletedMessage
            ? "bg-green-gradient border-dark-green"
            : "bg-white-gradient border-semi-white"
        }`}
      >
        <div className="flex justify-center flex-col items-center w-full">
          {!showCompletedMessage ? (
            <>
              <div className="flex flex-col gap-8 mt-10 mb-4 justify-center items-center">
                <div className="text-center font-semibold text-6xl mb-4">
                  {word}
                </div>
                <div>
                  <div className="flex font-semibold text-4xl gap-6 p-6 bg-purple text-semi-black">
                    <div className="min-h-[110px] min-w-[180px] flex flex-col items-center justify-center rounded-[24px] border-t-[2px] border-r-[2px] border-t-[16px] border-b-[2px] border-l-[2px] border-semi-white bg-semi-white">
                      <div className="text-4xl font-semibold">
                        {word.length}
                      </div>
                      <div>
                        <span className="font-semibold text-lg block mt-2">
                          Letters
                        </span>
                      </div>
                    </div>
                    <div className="min-h-[110px] min-w-[180px] flex flex-col items-center justify-center rounded-[24px] border-t-[2px] border-r-[2px] border-t-[16px] border-b-[2px] border-l-[2px] border-semi-white bg-semi-white">
                      <div className="text-4xl font-semibold">
                        {graphemes.split(" ").length}
                      </div>
                      <div>
                        <span className="font-semibold text-lg block mt-2">
                          Sounds
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="pb-4 flex justify-center items-center flex-col min-h-[50%]">
              <div className="font-semibold text-2xl mt-10 mb-2 text-white">
                Great Job!
              </div>
              <div className="font-semibold text-6xl mb-4 text-white">
                {word}
              </div>
              <img src={checkCheckPng} />
            </div>
          )}
        </div>
      </Window>
    </div>
  );
}

export default SpellOutIntervention;
