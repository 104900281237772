export default [
  // "twirled barked",
  // "off high shine her twirled",
  // "dog soft one stars",
  // "the soft",
  // "pos si bil i ty",
  // "P AA S AH B IH L AH T IY",
  "Sam's new coat was bright blue and very soft.",
  "One rainy day, Sam saw a rainbow in the sky.",
  '"My coat matches that rainbow!" she said with joy.',
  "As Sam twirled, her coat began to shimmer and shine.",
  "Suddenly, tiny stars appeared all over the coat!",
  "Sam's dog, Spot, barked happily at the sparkling coat.",
  '"Let\'s go on an adventure, Spot!" Sam said with a grin.',
  "She waved her arms, and the coat lifted her off the ground.",
  "Sam and Spot flew high, touching the fluffy clouds.",
  "They zoomed through the sky, making new rainbow trails.",
];
