import React, { useEffect } from "react";
import { DndProvider, useDrag, useDrop, useDragLayer } from "react-dnd";
import { HTML5Backend, getEmptyImage } from "react-dnd-html5-backend";
import puzzleInitPng from "../../assets/puzzle_init.png";
import puzzleRegularPng from "../../assets/puzzle_regular.png";
import puzzleEndPng from "../../assets/puzzle_end.png";
import puzzleInitNegativePng from "../../assets/puzzle_init_negative.png";
import puzzleRegularNegativePng from "../../assets/puzzle_regular_negative.png";
import puzzleEndNegativePng from "../../assets/puzzle_end_negative.png";

interface JigsawPiece {
  id: number;
  image: string;
  slotId: number;
  index: number;
  syllable: string;
  syllables: string[];
}

interface SlotProps {
  id: number;
  onDrop: (id: number, slotId: number, isCorrect: boolean) => void;
  children: React.ReactNode;
  syllables: string[];
}

interface PieceProps {
  piece: JigsawPiece;
  isDropped: boolean;
  showConnected?: boolean;
}

const ItemType = "PIECE";

const getPositions = (index: number, syllables: string[]) => {
  if (index === 0) {
    return [27, 28];
  }
  if (index === syllables.length - 1) {
    return [27, 58];
  }
  return [27, 44];
};

const Piece: React.FC<PieceProps> = ({ piece, isDropped, showConnected }) => {
  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemType,
    item: { id: piece.id, slotId: piece.slotId },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    // Use empty image to hide the default preview
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  const positions = getPositions(piece.id, piece.syllables);

  return (
    <div
      ref={drag}
      className="relative"
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",
        visibility: isDropped ? "hidden" : "visible",
        marginLeft: showConnected && piece.id > 0 ? "-45px" : "",
      }}
    >
      <div className="w-[160px] h-[100px]">
        <img src={piece.image} className="h-[108px]" />
        <span
          style={{
            top: `${positions[0]}px`,
            left: `${positions[1]}px`,
          }}
          className={`w-[60px] text-center absolute text-4xl font-bold text-white`}
        >
          {piece.syllable}
        </span>
      </div>
    </div>
  );
};

const CustomDragLayer = ({ pieces }: any) => {
  const {
    isDragging,
    item,
    currentOffset,
    clientOffset,
    initialClientOffset,
    initialSourceClientOffset,
  } = useDragLayer((monitor) => ({
    itemType: monitor.getItemType(),
    isDragging: monitor.isDragging(),
    item: monitor.getItem(),
    currentOffset: monitor.getSourceClientOffset(),
    clientOffset: monitor.getClientOffset(),
    initialClientOffset: monitor.getInitialClientOffset(),
    initialSourceClientOffset: monitor.getInitialSourceClientOffset(),
  }));

  if (
    !isDragging ||
    !currentOffset ||
    !clientOffset ||
    !initialClientOffset ||
    !initialSourceClientOffset
  ) {
    return null;
  }

  const offsetX =
    clientOffset.x - initialClientOffset.x + initialSourceClientOffset.x;
  const offsetY =
    clientOffset.y - initialClientOffset.y + initialSourceClientOffset.y;

  return (
    <div
      style={{
        pointerEvents: "none",
        position: "absolute",
        left: offsetX + "px",
        top: offsetY + "px",
        zIndex: 10000000,
      }}
    >
      <Piece piece={pieces[item.id]} isDropped={false} />
    </div>
  );
};

const Slot: React.FC<SlotProps> = ({ id, onDrop, children, syllables }) => {
  const [_, drop] = useDrop({
    accept: ItemType,
    drop: (item: { id: number }) => {
      const isCorrect = item.id + 1 === id;
      onDrop(item.id, id, isCorrect);
    },
  });

  const image = getImage(id - 1, syllables, "negative");

  return (
    <div
      ref={drop}
      style={{
        position: "relative",
        marginLeft: id > 1 ? "-40px" : "0",
      }}
    >
      <img src={image} />
      {children}
    </div>
  );
};

const getImage = (index: number, syllables: string[], type?: string) => {
  if (index === 0) {
    return type === "negative" ? puzzleInitNegativePng : puzzleInitPng;
  }
  if (index === syllables.length - 1) {
    return type === "negative" ? puzzleEndNegativePng : puzzleEndPng;
  }
  return type === "negative" ? puzzleRegularNegativePng : puzzleRegularPng;
};

const JigsawPuzzle = ({
  syllables,
  onCorrectDrop,
  onIncorrectDrop,
}: {
  syllables: string[];
  onCorrectDrop?: Function;
  onIncorrectDrop?: Function;
}) => {
  const pieces = syllables.map((syllable: string, index: number) => {
    const image = getImage(index, syllables);
    return { id: index, image, slotId: index + 1, syllable, syllables };
  });

  const [completedSlots, setCompletedSlots] = React.useState<number[]>([]);

  const [droppedPieces, setDroppedPieces] = React.useState<
    { id: number; slotId: number }[]
  >([]);

  const handleCorrectDrop = (id: number, slotId: number) => {
    setCompletedSlots([...completedSlots, slotId]);
    if (onCorrectDrop) {
      onCorrectDrop(id, slotId);
    }
  };

  const handleIncorrectDrop = (id: number, slotId: number) => {
    if (onIncorrectDrop) {
      onIncorrectDrop(id, slotId);
    }
  };

  const onDrop = (id: number, slotId: number, isCorrect: boolean) => {
    if (isCorrect) {
      setDroppedPieces([...droppedPieces, { id, slotId }]);
      handleCorrectDrop(id, slotId);
    } else {
      handleIncorrectDrop(id, slotId);
    }
  };

  const renderPiece = (piece: JigsawPiece) => {
    const isDropped = droppedPieces.some(
      (drop) => drop.id === piece.id && drop.slotId === piece.slotId
    );

    return <Piece key={piece.id} piece={piece} isDropped={isDropped} />;
  };
  const isFinished = completedSlots.length >= pieces.length;

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex flex-col justify-center items-center">
        <div className={`flex mb-4 ${isFinished ? "ml-[36px]" : ""}`}>
          {pieces.map((piece: any) => {
            if (completedSlots.includes(piece.slotId)) {
              return (
                <Piece
                  key={`completed-${piece.id}`}
                  piece={piece}
                  isDropped={false}
                  showConnected={true}
                />
              );
            }
            return (
              <Slot
                key={piece.slotId}
                id={piece.slotId}
                onDrop={onDrop}
                syllables={syllables}
              >
                <></>
              </Slot>
            );
          })}
        </div>
        {!isFinished && (
          <div className="flex">
            {pieces.map((piece: any) => renderPiece(piece))}
          </div>
        )}
        <CustomDragLayer pieces={pieces} />
      </div>
    </DndProvider>
  );
};

export default JigsawPuzzle;
