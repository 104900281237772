import { SignUp as ClerkSignUp } from "@clerk/clerk-react";
import { Navigate } from "react-router-dom";
import cubesTopLeft from "../../assets/cubes_top_left.png";
import ondulationBg from "../../assets/ondulationBg.svg";
import PageLayout from "../../components/PageLayout";
import { useAuth } from "../../context/AuthContext";

function SignUp() {
  const queryString = window.location.search; // Get the current query string
  const { userId } = useAuth();

  if (userId) {
    return <Navigate to={`/${queryString}`} replace />;
  }

  return (
    <PageLayout>
      <div className="flex justify-center items-center flex-col bg-purple-gradient w-full h-[100vh]">
        <img src={cubesTopLeft} className="absolute left-0 top-0 z-0" />
        <div className="z-10">
          <ClerkSignUp signInUrl="/sign-in" />
        </div>
        <img src={ondulationBg} className="absolute bottom-0 left-0" />
      </div>
    </PageLayout>
  );
}

export default SignUp;
