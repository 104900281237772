import React from "react";

interface TooltipProps {
  label: string;
  children: React.ReactNode;
  smallText?: boolean;
  className?: string;
}

const Tooltip: React.FC<TooltipProps> = ({
  label,
  children,
  smallText,
  className = "",
}) => {
  return (
    <span className={`cursor-pointer relative group ${className}`}>
      {children}
      <span
        className={`absolute ${
          smallText
            ? "whitespace-nowrap text-center w-auto"
            : "left-[95px] text-left px-4 py-4 w-40"
        } top-[-12px] bg-inverse left-1/2 z-90 mb-2 hidden -translate-x-1/2 rounded-md bg-black px-2 py-1 text-sm text-white group-hover:block`}
      >
        {label}
      </span>
    </span>
  );
};

export default Tooltip;
