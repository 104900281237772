export default [
  // "she saw many bees buzzing around",
  // "soft",
  `Bobby was on his way home from school one day. On his walk, he saw something green in the snow. He stopped and stared. He thought he was seeing things. Green in the snow? It couldn't be what it seemed to be, could it? He bent down in the snow and quickly dug it out. It was a five-dollar bill. He carefully smoothed it flat. He wondered if it was real money or just play money. It looked real. That made him feel good. This was his lucky day. But then he felt bad. He knew that if he ever lost five dollars he would cry and cry. Once, he dropped a dime on the floor, and it had rolled into the heating vent. He never saw the dime again.`,
  `I have a garden in my backyard and there are lots of vegetables in it. There are bees buzzing around the flowers. They help the plants grow. My mom helped my sister plant this garden last spring. They used many seeds to make the little plants come up from the soil. My sister said it was fun work, and she is glad she did it. She said it took them two months to see the first vegetables. They put a fence around the garden so that animals would not eat the plants. Everyone stops to look at the garden when they visit our house. They always ask about the vegetables in the garden. My sister tells them about each plant and how she grew it. She also tells them she wants to grow more vegetables. If she plants more seeds, it might be too crowded. She shows them the watering can and how she gives the plants water. My sister loves to talk about her garden.`,
  `I live in a house at the end of the street. Other kids always like to play in front of my house because not many cars drive near my house. That works well for me because I can run outside and find other people to play games with. Sometimes, we play freeze tag or bean bag tag. Other times, we put up goal posts and play soccer or hockey. My older sister is the goalie and she can stop any shot. If we don't have many kids, or if we are really bored, my sister will make up a new game for us to play. She once created a mix of baseball, soccer, and tag. It was a lot of fun, but it was hard to remember all the rules. When the sun sets we have to go back to our own homes and have dinner. We always say we will keep the same teams or scores for the next day, but we never do. It's a good street to live on and have friends.`,
  "A kitten wandered through fields Flowers bloomed the birds chirped the butterflies fluttered They played racing among tall grass and exploring hidden corners As sunset approached, golden light bathed the landscape. Tired yet content, the new friends rested, feeling peaceful and satisfied",
];

// a kitten wandered through fields flowers bloomed the birds chirped the butterflies fluttered they played racing among tall grass and exploring hidden corners as sunset approached golden light bathed the landscape tired yet content the new friends rested feeling peaceful and satisfied
