import { useState } from "react";

function Paginator({ items, showControls }: any) {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const component = items[currentIndex].component;

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < items.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  return (
    <div className="w-full flex justify-center">
      {component}
      {showControls && items.length > 1 && (
        <div className="w-full flex justify-between p-4 text-white">
          <button
            className="bg-blue-800"
            onClick={handlePrev}
            disabled={currentIndex === 0}
          >
            Previous
          </button>
          <button
            className="bg-blue-800"
            onClick={handleNext}
            disabled={currentIndex === items.length - 1}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
}

export default Paginator;
