import axios from "axios";
import { ResponseData, SpeechTokenType, UserType } from "../types/models";

const API_URL = import.meta.env.VITE_API_URL;

export const getUserSession = (): UserType | null => {
  const rawUserSession = localStorage.getItem("user-session");
  return rawUserSession ? JSON.parse(rawUserSession) : null;
};

export const api = axios.create({ baseURL: API_URL });

type SpeechTokenResponseType = Promise<ResponseData<SpeechTokenType>>;
export const getSpeechToken = async (): SpeechTokenResponseType => {
  return api.get("/get-speech-token");
};

export const getChatCompletion = async (prompt: string) => {
  return api.post(
    "/openai/chat-completion",
    {
      messages: [
        {
          role: "user",
          content: prompt,
        },
      ],
      format: "json_object",
    }
  );
};
