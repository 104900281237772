import React, { useEffect } from "react";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  isClosable?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  isClosable,
}) => {
  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        if (!isClosable) return;
        onClose();
      }
    };

    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [onClose]);

  if (!isOpen) return null;

  const handleOverlayClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (event.target === event.currentTarget) {
      if (!isClosable) return;
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
      onClick={handleOverlayClick}
    >
      <div className="min-w-[80vw] relative top-20 mx-auto w-11/12 sm:w-3/4 md:w-1/2 lg:w-1/3 shadow-lg rounded-[24px] bg-white">
        {children}
        {isClosable && (
          <button
            onClick={() => {
              onClose();
            }}
            className="text-white absolute top-0 right-0 mt-2 mr-2 bg-transparent hover:bg-gray-200 hover:text-black rounded-[24px] text-lg p-1.5 ml-auto inline-flex items-center"
          >
            <svg
              className="w-6 h-6"
              fill="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};

export default Modal;
