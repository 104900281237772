import { useAvatar } from "alpha-ai-avatar-sdk-react";
import { useSpeech } from "../context/SpeechContext";
import { useEffect, useRef, useState } from "react";

type AnyFunctionType = () => any;

const useWaitAvatar = (callback: AnyFunctionType) => {
  const { isAvatarSpeaking } = useAvatar();
  const speechCycleFlag = useRef(false);
  // console.log("wait avatar", isAvatarSpeaking);

  useEffect(() => {
    if (isAvatarSpeaking) {
      speechCycleFlag.current = true;
    } else if (!isAvatarSpeaking && speechCycleFlag.current) {
      speechCycleFlag.current = false;
      console.log("- AVATAR CALLBACK");
      callback();
    }
  }, [isAvatarSpeaking, callback]);
};

const useWaitUser = (callback: AnyFunctionType) => {
  const { tempFlag } = useSpeech();
  // console.log("wait user", tempFlag);
  const speechCycleFlag = useRef(false);

  useEffect(() => {
    if (tempFlag) {
      speechCycleFlag.current = true;
    } else if (!tempFlag && speechCycleFlag.current) {
      speechCycleFlag.current = false;
      console.log("- USER CALLBACK");
      callback();
    }
  }, [tempFlag, callback]);
};

interface ConversationType {
  queue: AnyFunctionType[];
  onFinished?: AnyFunctionType;
}

export const useConversation = (
  { queue = [], onFinished }: ConversationType,
  resetDependency?: any
) => {
  const initialized = useRef<any>(false);
  const shouldIgnoreNext = useRef<boolean>(false);
  // const { say, stop, isAvatarSpeaking, isConnected } = useAvatar();
  // const { handleStartRecording, handleStopRecording, recording } = useSpeech();
  const [currentQueue, setCurrentQueue] = useState(queue);
  const { isAvatarSpeaking, stop } = useAvatar();

  const runNext = (newQueue?: AnyFunctionType[]) => {
    // console.log("runNext shouldIgnoreNext?", shouldIgnoreNext);
    if (!isAvatarSpeaking && shouldIgnoreNext.current) {
      shouldIgnoreNext.current = false;
      return;
    } else if (isAvatarSpeaking) {
      stop();
    }
    const actualQueue = newQueue ?? currentQueue;
    // console.log(actualQueue.length, actualQueue);
    if (actualQueue.length === 0) {
      return onFinished && onFinished();
    }
    const [current, ...remaining] = actualQueue;
    setCurrentQueue(remaining);
    current();
  };

  useWaitAvatar(runNext);
  useWaitUser(runNext);

  useEffect(() => {
    const hasDependencyChanged =
      resetDependency && initialized.current !== resetDependency;
    if (initialized.current && !hasDependencyChanged) {
      // avoids double execution in dev due to <StrictMode>
      return;
    } else if (hasDependencyChanged) {
      // reset the conversation to the new queue
      runNext(queue);
    } else {
      // auto start
      runNext();
    }

    initialized.current = resetDependency ?? true;
    // return () => {
    //   stop();
    // };
  }, [resetDependency]);

  return {
    cancelConversation: () => {
      stop();
      setCurrentQueue([]);
    },
    ignoreNext: () => {
      shouldIgnoreNext.current = true;
    },
    cancelIgnore: () => {
      shouldIgnoreNext.current = false;
    },
  };
};
