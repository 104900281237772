export function cleanString(input = ""): string {
  const output = input
    .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()?\[\]]/g, "")
    .replace(/\s{2,}/g, " ")
    .replace(/\"/g, "")
    .replace(/\'/g, "")
    .toLowerCase();

  return output;
}

export function getAllIndexesOfWord({
  lessonPhraseWords,
  word,
}: {
  lessonPhraseWords: any[];
  word: any;
}): any {
  let indexes: number[] = [];

  lessonPhraseWords.forEach((currentWord, index) => {
    if (currentWord === word) {
      indexes.push(index);
    }
  });

  return indexes;
}

export function numberToWords(sentence: string): string {
  const numberWords = [
    "zero",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
  ];

  return sentence.replace(/\b\d+\b/g, (match: any) => {
    const num = parseInt(match, 10);
    return num <= 100 ? numberWords[num] : match;
  });
}

export function convertOrdinal(sentence: string): string {
  switch (sentence) {
    case "1st":
      return "first";
    case "2nd":
      return "second";
    case "3rd":
      return "third";
    case "4th":
      return "fourth";
    case "5th":
      return "fifth";
    case "6th":
      return "sixth";
    case "7th":
      return "seventh";
    case "8th":
      return "eighth";
    case "9th":
      return "ninth";
    case "10th":
      return "tenth";
  }

  return sentence;
}

export const getLastOccurrence = ({
  phrase,
  word,
  remainingWords,
  indexes,
}: any) => {
  const amountOfRepeatingWordOnOriginalPhrase = phrase
    .split(" ")
    .filter((phraseWord: string) => {
      return phraseWord === word;
    }).length;

  const amountOfRepeatingWordOnRemainingWords = remainingWords.filter(
    (remainingWord: string) => {
      return remainingWord === word;
    }
  ).length;

  const delta =
    amountOfRepeatingWordOnOriginalPhrase -
    amountOfRepeatingWordOnRemainingWords;

  return indexes[delta];
};

export function reduceToOriginalPart(arrayOfArrays: any, originalPhrase: any) {
  let finalArray: any = [];
  let currentIndex = 0;

  arrayOfArrays.forEach((arr: any) => {
    arr.forEach((word: any) => {
      let wordIndex = originalPhrase.indexOf(word, currentIndex);

      // Ensure the word is in the correct sequence and has not already been added
      if (wordIndex !== -1 && wordIndex === currentIndex) {
        finalArray.push(word);
        currentIndex = wordIndex + word.length + 1; // Update currentIndex to move to the next word
      }
    });
  });

  return finalArray;
}
