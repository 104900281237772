import dayjs from "dayjs";
import { useState } from "react";

export const useTimer = () => {
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);

  const startTimer = () => {
    setStartTime(new Date());
  };
  const stopTimer = () => {
    setEndTime(new Date());
  };
  const resetTimer = () => {
    setStartTime(null);
    setEndTime(null);
  };

  const durationInMin =
    startTime && endTime
      ? dayjs(endTime).diff(startTime, "minute", true)
      : null;

  const durationInSec =
    startTime && endTime ? dayjs(endTime).diff(startTime, "second") : null;

  return {
    startTimer,
    stopTimer,
    resetTimer,
    durationInMin,
    durationInSec,
  };
};
