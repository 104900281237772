import { useState, useRef, useEffect } from "react";
import { useAppConfig } from "../../context/AppConfig";

const slotsClass =
  "mx-2 p-2 rounded-md w-12 text-center cursor-pointer transition-all duration-200 ease-in-out transform hover:scale-105 hover:bg-green-300";

const slots = [0, 1, 2, 3, 4];

function ConfigPanel() {
  const { configs, currentConfigIndex, saveConfig, switchConfig } =
    useAppConfig();

  const [isExpanded, setIsExpanded] = useState(true);
  const panelRef = useRef<HTMLDivElement>(null);

  const togglePanel = () => setIsExpanded(!isExpanded);

  const handleClickOutside = (event: MouseEvent) => {
    if (panelRef.current && !panelRef.current.contains(event.target as Node)) {
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={panelRef}
      className="max-w-[500px] absolute left-[15px] top-[15px] z-50 bg-gray-700 p-4 rounded-lg shadow-lg"
    >
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={togglePanel}
      >
        <h1 className="text-white text-lg font-semibold flex justify-center items-center">
          Config Panel{" "}
          {!isExpanded && (
            <svg
              className="w-2.5 h-2.5 ms-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          )}
        </h1>
      </div>
      {isExpanded && (
        <div>
          <div className="text-sm text-gray-400 mt-4">
            These are config slots, so you can easily switch between configs.
          </div>
          <div className="flex justify-between mb-12 mt-2">
            {slots.map((slotIndex) => (
              <div
                key={slotIndex}
                className={`${slotsClass} ${
                  currentConfigIndex === slotIndex
                    ? "bg-green-500 text-white"
                    : "bg-white text-gray-700"
                }`}
                onClick={() => {
                  switchConfig(slotIndex);
                }}
              >
                {slotIndex + 1}
              </div>
            ))}
          </div>

          {configs.map((config, index) => (
            <div
              key={index}
              className={`${currentConfigIndex !== index ? "hidden" : ""}`}
            >
              <div className="mb-4">
                <label className="text-white">
                  Pronunciation Score Threshold:
                  <input
                    type="number"
                    value={
                      config.pronunciationScoreThreshold !== undefined
                        ? config.pronunciationScoreThreshold
                        : ""
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === "") {
                        saveConfig({
                          config: {
                            ...config,
                            pronunciationScoreThreshold: undefined,
                          },
                          index,
                        });
                        return;
                      }

                      const numericValue = Number(value);
                      if (numericValue >= 1) {
                        saveConfig({
                          config: {
                            ...config,
                            pronunciationScoreThreshold: numericValue,
                          },
                          index,
                        });
                      }
                    }}
                    className="text-black ml-2 p-2 rounded bg-gray-200 focus:bg-white focus:outline-none focus:ring-2 focus:ring-green-500 w-16"
                    min={0}
                    max={100}
                  />
                </label>
                <div className="text-sm text-gray-400">
                  Value between 0 and 100
                </div>
                <div className="text-sm text-gray-400">
                  Any result BELLOW the threshold will be considered a
                  mispronunciation.
                </div>
              </div>

              <div className="mb-4">
                <label className="text-white">
                  Unexpected Breaks Threshold:
                  <input
                    type="number"
                    step="0.01"
                    max={30}
                    value={
                      config.unexpectedBreakThreshold !== undefined
                        ? config.unexpectedBreakThreshold
                        : ""
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === "") {
                        saveConfig({
                          config: {
                            ...config,
                            unexpectedBreakThreshold: undefined,
                          },
                          index,
                        });
                        return;
                      }

                      const numericValue = Number(value);
                      if (numericValue >= 1) {
                        saveConfig({
                          config: {
                            ...config,
                            unexpectedBreakThreshold: numericValue,
                          },
                          index,
                        });
                      }
                    }}
                    className="text-black ml-2 p-2 rounded bg-gray-200 focus:bg-white focus:outline-none focus:ring-2 focus:ring-green-500 w-16"
                  />
                </label>
                <div className="text-sm text-gray-400">
                  Value between 0.01 and 30
                </div>
                <div className="text-sm text-gray-400">
                  Any result ABOVE the threshold will be considered an
                  unexpected break.
                </div>
              </div>

              <div className="mb-4">
                <label className="text-white">
                  Use Azure Pronunciation:
                  <input
                    type="checkbox"
                    checked={config.useAzureMispronunciationFlag}
                    onChange={(e) => {
                      saveConfig({
                        config: {
                          ...config,
                          useAzureMispronunciationFlag: e.target.checked,
                        },
                        index,
                      });
                    }}
                    className="ml-2 transform scale-125 focus:outline-none focus:ring-2 focus:ring-green-500"
                  />
                </label>
                <div className="text-sm text-gray-400 mb-1">
                  Account for what Azure thinks is a mispronunciation.
                </div>
                <div className="text-sm text-gray-400 mb-2">
                  If turned on: Azures opinion takes priority, so if Azure says
                  it's a mispronunciation, we agree, but if Azure says it's not,
                  we still use the score threshold.
                </div>
                <div className="text-sm text-gray-400">
                  If turned off: We just ignore what Azure says and only look at
                  the score threshold.
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ConfigPanel;
