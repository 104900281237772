import { useAppConfig } from "../context/AppConfig";

const useSpeechErrors = () => {
  const { currentConfig } = useAppConfig();
  const {
    unexpectedBreakThreshold = 3,
    pronunciationScoreThreshold = 75,
    useAzureMispronunciationFlag,
  } = currentConfig;

  const isOmissionError = (item: any): boolean => {
    return item.PronunciationAssessment?.ErrorType === "Omission";
  };

  const isInsertionError = (item: any): boolean => {
    return item.PronunciationAssessment?.ErrorType === "Insertion";
  };

  const isMispronunciationError = (item: any): boolean => {
    const { ErrorType, AccuracyScore } = item.PronunciationAssessment;

    const isErrorTypeRelevant =
      !isInsertionError(item) && !isOmissionError(item);

    if (
      (useAzureMispronunciationFlag && ErrorType === "Mispronunciation") ||
      (AccuracyScore < pronunciationScoreThreshold && isErrorTypeRelevant)
    ) {
      return true;
    }

    return false;
  };

  const isMonotoneError = (item: any): boolean => {
    return (
      item.PronunciationAssessment?.Feedback?.Prosody?.Intonation?.ErrorTypes &&
      item.PronunciationAssessment.Feedback.Prosody.Intonation.ErrorTypes.includes(
        "Monotone"
      )
    );
  };

  const isUnexpectedBreakError = (item: any): boolean => {
    return (
      item?.PronunciationAssessment?.Feedback?.Prosody?.Break
        ?.UnexpectedBreak &&
      item.PronunciationAssessment.Feedback.Prosody.Break.UnexpectedBreak
        .Confidence > unexpectedBreakThreshold
    );
  };

  function countErrors(words: any) {
    if (!words || !words.length) return 0;

    const groupErrors = words.map((word: any) => {
      const errors = [];

      if (isMispronunciationError(word)) {
        errors.push("Mispronunciation");
      }

      if (isOmissionError(word)) {
        errors.push("Omission");
      }
      if (isInsertionError(word)) {
        errors.push("Insertion");
      }

      if (isUnexpectedBreakError(word)) {
        errors.push("UnexpectedBreak");
      }

      return { ...word, errors };
    });

    return groupErrors.filter((item: any) => item.errors.length > 0).length;
  }

  return {
    countErrors,
    isMispronunciationError,
    isUnexpectedBreakError,
    isMonotoneError,
    isOmissionError,
    isInsertionError,
  };
};

export default useSpeechErrors;
