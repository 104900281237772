import { Avatar, useAvatar } from "alpha-ai-avatar-sdk-react";
import alice from "../../assets/alice.png";

export const RichAvatar: React.FC<{
  message: string;
  useMouthZoom?: boolean;
  size?: string;
  freeze?: boolean;
}> = ({ useMouthZoom = false, size = "160", freeze }) => {
  const { stop, isConnected, isAvatarSpeaking } = useAvatar();

  function handleSay() {
    if (isAvatarSpeaking) {
      return stop();
    }
    // Feature removed for now, they don't want the click making the avatar speaking
    // say(message, { prosody: { rate: "-10%" } });
  }
  const sizeInPixels = `${size}px`;
  const avatarStyle = useMouthZoom
    ? { height: "450px", width: "450px", minWidth: "450px", minHeight: "450px" }
    : {
        height: sizeInPixels,
        width: sizeInPixels,
        minWidth: "120px",
        minHeight: "120px",
      };

  const className = `relative rounded-full overflow-hidden`;

  return (
    <div
      style={{
        width: sizeInPixels,
        height: sizeInPixels,
        minHeight: "120px",
        minWidth: "120px",
      }}
      className={className}
    >
      <img className={`absolute z-${freeze ? "50" : "0"}`} src={alice} />
      {isConnected && !freeze && (
        <div
          className={`flex flex-col gap-4 items-center absolute z-10 ${
            useMouthZoom ? "-top-[150%] -left-[93%]" : ""
          }`}
          style={avatarStyle}
        >
          <Avatar style={avatarStyle} onClick={handleSay} />
        </div>
      )}
    </div>
  );
};
