import { useLocation, useNavigate } from "react-router-dom";
import PageLayout from "../../components/PageLayout";
import PhraseVisualizer from "../../components/PhraseVisualizer";
import { useInterventions } from "../../context/InterventionsContext";
import {
  IIntervention,
  InterventionStatus,
} from "../../context/InterventionsContext/InterventionsContext";
import { useEffect, useRef, useState } from "react";
import phrasesMock from "../../mock/phrases";
import InterventionsList from "../../components/InterventionsList";
import { useSpeech } from "../../context/SpeechContext";
import { useLesson } from "../../context/LessonContext";
import Window from "../../components/Window";
import { RichAvatar } from "../../components/Avatar/RichAvatar";
import { useAvatar } from "alpha-ai-avatar-sdk-react";
import Spinner from "../../components/Spinner";
import ondulationBgPurple from "../../assets/ondulationBgPurple.svg";
import cubesBottomRight from "../../assets/cubes_bottom_right.png";

import StepsProgressBar from "../../components/StepsProgressBar";
import { cleanString } from "../../utils/string";
import useSpeechErrors from "../../hooks/useSpeechErrors";
import MicButton from "../../components/MicButton";

// let TIMEOUT_WAIT_FOR_ERRORS_SPEECH: any = null;

const totalLessons = phrasesMock.length - 1;

function Lesson() {
  const location = useLocation();
  const queryString = location.search;
  const params = new URLSearchParams(queryString);
  const skipIntro =
    params.get("skipIntro") && params.get("skipIntro") === "true";

  const [micButtonIsEnabled, setMicButtonIsEnabled] = useState<boolean>(
    !!skipIntro
  );
  const [showLoadButton, setShowLoadButton] = useState(false);
  const [interventionInProgress, setInterventionInProgress] = useState(false);

  const navigate = useNavigate();
  const {
    setLessonPhrase,
    lessonPhrase,
    lessonStep,
    setLessonStep,
    blockUserSpeech,
  } = useLesson();

  const { isMispronunciationError } = useSpeechErrors();

  const {
    setCurrentPhrase,
    currentSpeechResult,
    setNextWord,
    setNextWordIndex,
    nextWordIndex,
    resetAllStates,
    handleStopRecording,
    remainingWordsRef,
  } = useSpeech();

  const { interventions } = useInterventions();

  useEffect(() => {
    setNextWord(cleanString(phrasesMock[lessonStep]).split(" ")[0]);
    setNextWordIndex(-1);
    setLessonPhrase(cleanString(phrasesMock[lessonStep].toLowerCase()));
    setTimeout(() => {
      setShowLoadButton(true);
    }, 6000);
  }, []);

  useEffect(() => {
    if (!lessonPhrase) return;
    setCurrentPhrase(lessonPhrase);
    setNextWord(lessonPhrase.split(" ")[0]);
    setNextWordIndex(-1);
  }, [lessonPhrase]);

  useEffect(() => {
    const pendingInterventions = interventions.filter(
      (intervention: IIntervention) => {
        return intervention.status === InterventionStatus.PENDING;
      }
    );

    if (pendingInterventions.length > 0) {
      setCurrentPhrase(pendingInterventions[0].word);
      setInterventionInProgress(true);
    } else {
      setCurrentPhrase(remainingWordsRef.current.join(" "));
      setInterventionInProgress(false);
    }
  }, [interventions]);

  useEffect(() => {
    const interventionsCompleted = interventions.every(
      (intervention: any) =>
        intervention.status === InterventionStatus.COMPLETED
    );

    const errors = currentSpeechResult.filter((item: any) => {
      return isMispronunciationError(item);
    });

    const totalWords = lessonPhrase.split(" ").length;

    if (
      interventionsCompleted &&
      errors.length === 0 &&
      nextWordIndex === totalWords - 1 &&
      lessonPhrase?.length > 0
    ) {
      // if (TIMEOUT_WAIT_FOR_ERRORS_SPEECH) {
      //   clearTimeout(TIMEOUT_WAIT_FOR_ERRORS_SPEECH);
      // }
      // TIMEOUT_WAIT_FOR_ERRORS_SPEECH = setTimeout(() => {
      if (lessonStep >= totalLessons) {
        handleStopRecording();
        navigate(`/score${queryString}`);
        return;
      }

      resetAllStates();

      setLessonPhrase(phrasesMock[lessonStep + 1]);
      setCurrentPhrase(phrasesMock[lessonStep + 1]);
      setLessonStep(lessonStep + 1);
      // }, 1000);
      return;
    }
  }, [currentSpeechResult, interventions]);

  const { isConnected, say } = useAvatar();
  const isInitialized = useRef(false);

  useEffect(() => {
    if (skipIntro) return;

    if (isConnected && !isInitialized.current) {
      isInitialized.current = true;
      // setTimeout is needed to ensure `say` works consistently - it fails a lot of the times even though isConnected = true
      setTimeout(() => {
        say(
          "Hello. We're going to start a lesson now." +
            " When you're ready, click the start button and begin reading the sentence on the screen." +
            " I'll be listening and ready to help out if you ever get stuck. Let's read!"
        );
      }, 500);

      setTimeout(() => {
        setMicButtonIsEnabled(true);
      }, 14500);
    }
  }, [isConnected]);

  return (
    <PageLayout className="pt-20 bg-[#6d09fc]">
      {!isConnected ? (
        <div className="mt-12">
          {showLoadButton ? (
            <button
              onClick={() => {
                setShowLoadButton(false);
              }}
              className="mt-4 border-none w-fit rounded-full border-[2px] text-white font-bold text-2xl bg-gradient-to-b from-[#00D0FF] to-[#0067F5] bg-opacity-30 shadow-xl"
            >
              Click to Start Lesson!
            </button>
          ) : (
            <Spinner className="!w-[100px] !h-[100px]" />
          )}
        </div>
      ) : (
        <>
          {!interventionInProgress ? (
            <div className="flex flex-col justify-center items-center w-full">
              <Window noPadding className="w-[690px] py-6 px-8 mb-4">
                <div className="flex">
                  <div className="mr-8 w-[125px] h-[129px] border-t-[2px] border-r-[2px] border-b-[16px] border-l-[2px] border-[#3800D7] rounded-full">
                    <RichAvatar size="120" message={phrasesMock[lessonStep]} />
                  </div>
                  <div className="w-full flex flex-col">
                    <StepsProgressBar
                      steps={totalLessons + 1}
                      currentStep={lessonStep}
                    />

                    <div className="flex justify-between align-center h-full">
                      <div className="self-center h-[48px] flex justify-center items-center min-w-[136px] text-white text-xl font-bold rounded-xl border border-white/90 bg-white/5">
                        <span className="">
                          Exercise {parseInt(lessonStep as string) + 1}
                        </span>
                      </div>
                      <MicButton
                        blockUserSpeech={blockUserSpeech}
                        micButtonIsEnabled={micButtonIsEnabled}
                      />
                    </div>
                  </div>
                </div>
              </Window>
              <Window
                noPadding
                className="p-8 bg-white-gradient border-semi-white w-[690px] h-[80%] justify-center items-center"
              >
                <PhraseVisualizer
                  width="500"
                  lessonPhrase={phrasesMock[lessonStep]}
                />
              </Window>
            </div>
          ) : (
            <InterventionsList interventions={interventions} />
          )}
        </>
      )}
      <img src={ondulationBgPurple} className="absolute bottom-0 bg-red" />
      <img src={cubesBottomRight} className="absolute right-0 bottom-0 z-1" />
    </PageLayout>
  );
}

export default Lesson;
