export function averageGlobalScores(scores: any) {
  const accuracyScore =
    scores.reduce((acc: any, item: any) => {
      return acc + item.AccuracyScore;
    }, 0) / scores.length;

  const completenessScore =
    scores.reduce((acc: any, item: any) => {
      return acc + item.CompletenessScore;
    }, 0) / scores.length;

  const fluencyScore =
    scores.reduce((acc: any, item: any) => {
      return acc + item.FluencyScore;
    }, 0) / scores.length;

  const pronScore =
    scores.reduce((acc: any, item: any) => {
      return acc + item.PronScore;
    }, 0) / scores.length;

  const prosodyScore =
    scores.reduce((acc: any, item: any) => {
      return acc + item.ProsodyScore;
    }, 0) / scores.length;

  return {
    accuracyScore,
    completenessScore,
    fluencyScore,
    pronScore,
    prosodyScore,
  };
}
