import Window from "../../../components/Window";

const ScoreCard: React.FC<{ label: string; score: number | string }> = ({
  label,
  score,
}) => {
  return (
    <Window
      noPadding
      gradient="py-4 w-full border-[#C36F00] bg-gradient-to-b from-[#FFAB02] to-[#FF7D01]"
    >
      <div className="text-white text-7xl font-semibold">{score}</div>
      <div>
        <span className="text-white font-semibold text-xl block mt-2">
          {label}
        </span>
      </div>
    </Window>
  );
};
export default ScoreCard;
