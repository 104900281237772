import { useSpeech } from "../../context/SpeechContext";

import ondulationBgGray from "../../assets/ondulationBgGray.svg";
import cubesBottomRight from "../../assets/cubes_bottom_right.png";
import { useEffect, useState } from "react";
import Spinner from "../../components/Spinner";
import PageLayout from "../../components/PageLayout";
import { calculateOverallPronunciationScore } from "../../utils/codeFromAzure";
import { useLesson } from "../../context/LessonContext";
import { cleanString } from "../../utils/string";
import FluencyBreakdown from "../../components/FluencyBreakdown/FluencyBreakdown";
import Window from "../../components/Window";
import { averageGlobalScores } from "./helpers";
import ScoreCard from "./components/ScoreCard";
import ScoreCardFlat from "./components/ScoreCardFlat";
import useSpeechErrors from "../../hooks/useSpeechErrors";
import GoHomeButton from "../../components/GoHomeButton";

const FullScorePage = () => {
  const queryString = window.location.search;
  const { countErrors } = useSpeechErrors();
  const [loading, setLoading] = useState(true);
  const { analyzedWordsWithErrors, timer } = useSpeech();
  const { lessonPhrase } = useLesson();
  const [result, setResult] = useState<any>([]);

  const goHome = () => {
    window.location.href = `/${queryString}`;
  };

  // Redirect to home if user didn't come to this route from a assessment
  useEffect(() => {
    if (!lessonPhrase) {
      goHome();
    }
  }, []);

  const { accuracyScore, fluencyScore, prosodyScore } = averageGlobalScores(
    analyzedWordsWithErrors.scores
  );

  const correctWords = result.filter(
    (item: any) => item.PronunciationAssessment.ErrorType === "None"
  );

  const wcpm = !timer.durationInMin
    ? "N/A"
    : Math.floor(correctWords.length / timer.durationInMin);

  const durationParts: any = !timer.durationInSec
    ? []
    : [Math.trunc(timer.durationInSec / 60), timer.durationInSec % 60];
  if (durationParts.length === 2) {
    durationParts[1] =
      durationParts[1] < 10 ? `0${durationParts[1]}` : durationParts[1];
  }

  const errorsCount = countErrors(result);

  useEffect(() => {
    if (analyzedWordsWithErrors.words.length !== 0) {
      const result = calculateOverallPronunciationScore({
        allWords: structuredClone(analyzedWordsWithErrors.words),
        currentText: structuredClone(analyzedWordsWithErrors.words).map(
          (item: any) => item.Word
        ),
        language: "en-AU",
        reference_text: cleanString(lessonPhrase),
      });

      setResult(result);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [analyzedWordsWithErrors.words]);

  if (loading || !result || !result.length)
    return (
      <PageLayout className="items-center justify-center h-[100vh]">
        <Spinner className="!w-[100px] !h-[100px]" />
      </PageLayout>
    );

  const totalOmissions = result.filter(
    (item: any) => item.PronunciationAssessment.ErrorType === "Omission"
  ).length;

  const totalWordsInOriginalPhrase = lessonPhrase.split(" ").length;

  const completenessScore = Math.floor(
    ((totalWordsInOriginalPhrase - totalOmissions) /
      totalWordsInOriginalPhrase) *
      100
  );

  const pronScore =
    result
      .filter(
        (item: any) => item?.PronunciationAssessment?.ErrorType !== "Omission"
      )
      .reduce((acc: number, item: any) => {
        return acc + item.PronunciationAssessment.AccuracyScore;
      }, 0) / result.length;

  return (
    <div
      style={{
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right bottom",
        backgroundImage: `url(${cubesBottomRight}), url(${ondulationBgGray})`,
      }}
    >
      <PageLayout className="w-[100vw]">
        <Window noPadding className="min-w-[832px] mb-8 pb-2 px-4 mt-4">
          <div className="w-full text-black flex justify-center text-center my-4 flex-col">
            <div className="flex justify-between">
              <div className="w-full">
                <ScoreCard
                  label="Pronunciation Score"
                  score={Math.floor(pronScore)}
                />
                <div className="grid grid-cols-2 gap-2 mt-4">
                  <ScoreCardFlat
                    infoText={`Accuracy indicates how closely the phonemes match a native speaker's pronunciation.`}
                    label="Accuracy"
                    score={Math.floor(accuracyScore)}
                  />
                  <ScoreCardFlat
                    infoText={`Fluency indicates how closely the speech matches a native speaker's use of silent breaks between words.`}
                    label="Fluency"
                    score={Math.floor(fluencyScore)}
                  />
                  <ScoreCardFlat
                    infoText={`Completeness of the speech, calculated by the ratio of pronounced words to the input reference text.`}
                    label="Completeness"
                    score={Math.floor(completenessScore)}
                  />
                  <ScoreCardFlat
                    infoText={`Prosody indicates how natural the given speech is, including stress, intonation, speaking speed, and rhythm.`}
                    label="Prosody"
                    score={Math.floor(prosodyScore)}
                  />
                </div>
              </div>
              <div className="w-16" />
              <div className="w-full">
                <ScoreCard label="Words Correct per Min." score={wcpm} />
                <div className="grid grid-cols-2 gap-2 mt-4">
                  <ScoreCardFlat
                    label="Words Read"
                    score={analyzedWordsWithErrors.words.length}
                  />
                  <ScoreCardFlat label="Time" score={durationParts.join(":")} />
                  <ScoreCardFlat
                    label="Words Correct"
                    score={correctWords.length}
                  />
                  <ScoreCardFlat label="Errors" score={errorsCount} />
                </div>
              </div>
            </div>
          </div>
        </Window>
        <FluencyBreakdown words={result} />

        <div className="mb-4 w-full justify-center flex">
          <GoHomeButton />
        </div>
      </PageLayout>
    </div>
  );
};

export default FullScorePage;
