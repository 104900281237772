import { useNavigate } from "react-router-dom";
import PageLayout from "../../components/PageLayout";
import ondulationBg from "../../assets/ondulationBg.svg";
import cubesTopLeft from "../../assets/cubes_top_left.png";
import Window from "../../components/Window";
import { LocaleSelector } from "../LocaleSelector/LocaleSelector";
import ConfigPanel from "../../components/ConfigPanel";

function Home() {
  const navigate = useNavigate();
  const queryString = window.location.search; // Get the current query string
  const searchParams = new URLSearchParams(queryString);
  const showConfigPanel = searchParams.get("config") === "true";

  return (
    <PageLayout className="justify-center relative">
      <LocaleSelector />
      {showConfigPanel && <ConfigPanel />}
      <div className="flex justify-center items-center flex-col bg-purple-gradient w-full h-[100vh]">
        <img src={cubesTopLeft} className="absolute left-0 top-0 z-0" />
        <Window className="min-w-[50%] min-h-1/2 justify-center items-center">
          <h1 className="text-5xl text-white leading-[60px] font-bold text-center mb-4">
            Welcome To
            <br />
            Fluency Coach!
          </h1>
          <button
            onClick={() => {
              navigate(`/lesson${queryString}`);
            }}
            className="mt-4 border-none w-fit rounded-full border-[2px] text-white font-bold text-2xl bg-gradient-to-b from-[#00D0FF] to-[#0067F5] bg-opacity-30 shadow-xl"
          >
            Start Lesson
          </button>
          <div className="mt-2 text-white font-bold text-xl">Or</div>
          <button
            onClick={() => {
              navigate(`/assessment${queryString}`);
            }}
            className="mt-4 border-none w-fit rounded-full border-[2px] text-white font-bold text-2xl bg-gradient-to-b from-[#00D0FF] to-[#0067F5] bg-opacity-30 shadow-xl"
          >
            Start Assessment
          </button>
        </Window>
      </div>
      <img src={ondulationBg} className="absolute bottom-0 left-0" />
    </PageLayout>
  );
}

export default Home;
