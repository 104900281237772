import { ClerkProvider, useAuth as useClerkAuth } from "@clerk/clerk-react";
import React, { PropsWithChildren, useEffect } from "react";
import { api } from "../utils/api";
import { useNavigate } from "react-router-dom";

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const queryString = window.location.search;
  const navigate = useNavigate();

  return (
    <ClerkProvider
      routerPush={(to) => navigate(to + queryString)}
      routerReplace={(to) => navigate(to + queryString, { replace: true })}
      publishableKey={PUBLISHABLE_KEY}
      signInForceRedirectUrl={`/${queryString}`}
      signUpForceRedirectUrl={`/${queryString}`}
    >
      <AxiosInterceptor>{children}</AxiosInterceptor>
    </ClerkProvider>
  );
};

function AxiosInterceptor({ children }: PropsWithChildren) {
  const { getToken } = useClerkAuth();

  useEffect(() => {
    const interceptor = api.interceptors.request.use(async (config) => {
      const token = await getToken();
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    });

    return () => {
      api.interceptors.request.eject(interceptor);
    };
  }, [getToken]);

  return children;
}

export const useAuth = () => {
  return useClerkAuth();
};
