import controlBackSvg from "../../../assets/controlBack.svg";
import controlForwardSvg from "../../../assets/controlForward.svg";
import navNodeSvg from "../../../assets/navNode.svg";
import navNodeNegativeSvg from "../../../assets/navNodeNegative.svg";

interface PageControlProps {
  onNext: () => void;
  onPrev: () => void;
  currentPage: number;
  totalPages: number;
  onNodeClick: (index: number) => void;
}

function PageControl({
  onNext,
  onPrev,
  totalPages,
  currentPage = 0,
  onNodeClick,
}: PageControlProps) {
  return (
    <div className="flex">
      <div
        className={`${
          currentPage - 1 < 0 ? "opacity-50 cursor-not-allowed" : ""
        } border border-white rounded-full w-16 h-16 flex justify-center items-center cursor-pointer`}
        onClick={onPrev}
      >
        <img src={controlBackSvg} className="mr-[3px]" />
      </div>
      <div className="flex mx-4 justify-center items-center">
        {Array.from({ length: totalPages }).map((_, index) => (
          <img
            key={index}
            onClick={() => {
              onNodeClick(index);
            }}
            src={index === currentPage ? navNodeSvg : navNodeNegativeSvg}
            className="w-4 h-4 mx-1 cursor-pointer"
            alt={`Page ${index + 1}`}
          />
        ))}
      </div>
      <div
        className={`${
          currentPage + 1 > totalPages - 1
            ? "opacity-50 cursor-not-allowed"
            : ""
        } border border-white rounded-full w-16 h-16 flex justify-center items-center cursor-pointer`}
        onClick={onNext}
      >
        <img src={controlForwardSvg} className="mr-[3px]" />
      </div>
    </div>
  );
}

export default PageControl;
