import { useSpeech } from "../../context/SpeechContext";
import openMicPng from "../../assets/openMic.png";
import closedMicPng from "../../assets/closedMic.png";

interface MicButtonProps {
  micButtonIsEnabled: boolean;
  blockUserSpeech?: boolean;
}

function MicButton({ micButtonIsEnabled, blockUserSpeech }: MicButtonProps) {
  const { handleStartRecording, recognitionStarted, setRecognitionStarted } =
    useSpeech();

  return (
    <div className="flex items-center justify-center">
      {!recognitionStarted && (
        <button
          onClick={() => {
            if (!micButtonIsEnabled) return;
            if (!recognitionStarted) {
              setRecognitionStarted(true);
              handleStartRecording();
            }
          }}
          className={`mr-2 border-none w-fit rounded-full border-[2px] text-white font-bold text-2xl bg-gradient-to-b from-[#00D0FF] to-[#0067F5] bg-opacity-30 shadow-xl ${
            !micButtonIsEnabled ? "opacity-50 cursor-default" : "cursor-pointer"
          }`}
        >
          Start
        </button>
      )}
      {recognitionStarted && !blockUserSpeech && (
        <span className="font-bold text-lg mr-4 text-white">Listening...</span>
      )}
      <div>
        {recognitionStarted && !blockUserSpeech ? (
          <img src={closedMicPng} />
        ) : (
          <img className="cursor-pointer" src={openMicPng} />
        )}
      </div>
    </div>
  );
}

export default MicButton;
