import { useState } from "react";

interface LocaleOptionType {
  label: string;
  value: string;
}
const LOCALE_OPTIONS: LocaleOptionType[] = [
  { label: "English (United States)", value: "en-US" },
  { label: "English (United Kingdom)", value: "en-GB" },
  { label: "English (Australia)", value: "en-AU" },
  { label: "English (Canada)", value: "en-CA" },
  { label: "English (India)", value: "en-IN" },
];

export const LocaleSelector = () => {
  const [currentLocale, setLocale] = useState(
    localStorage.getItem("user-locale") ?? "en-US"
  );
  const [isOpen, setOpen] = useState(false);
  const toggleDropdown = () => {
    setOpen(!isOpen);
  };
  const onLocaleChange = (option: LocaleOptionType) => {
    setLocale(option.value);
    setOpen(false);
    localStorage.setItem("user-locale", option.value);
  };

  return (
    <>
      <div
        className="absolute top-0 left-0 z-10 w-[100vw] h-[100vh]"
        onClick={() => {
          setOpen(false);
        }}
      />

      <div className="absolute right-10 top-10 z-20">
        <div className="flex flex-col items-end">
          <button
            id="dropdownRadioButton"
            data-dropdown-toggle="dropdownDefaultRadio"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            type="button"
            onClick={toggleDropdown}
          >
            {`${
              LOCALE_OPTIONS.find((option) => option.value === currentLocale)!
                .label
            } `}
            <svg
              className="w-2.5 h-2.5 ms-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </button>

          <div
            id="dropdownDefaultRadio"
            className={`z-10 ${
              isOpen ? "" : "hidden"
            } bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600`}
          >
            <ul
              className="p-3 space-y-3 text-sm text-gray-700 dark:text-gray-200"
              aria-labelledby="dropdownRadioButton"
            >
              {LOCALE_OPTIONS.map((option) => (
                <li key={option.value}>
                  <div className="flex items-center">
                    <input
                      checked={option.value === currentLocale}
                      onChange={() => {
                        onLocaleChange(option);
                      }}
                      id={`locale-option-${option.value}`}
                      type="radio"
                      value={option.value}
                      name="locale-selection"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      htmlFor={`locale-option-${option.value}`}
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      {option.label}
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
