import lodash from "lodash";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import { cleanString } from "./string";

export class SpeechAnalysis {
  constructor({
    referenceText,
    speechToken,
    onRecognizing,
    onRecognized,
    onCancelled,
    onSessionStopped,
  }: any) {
    const self = this;

    self.referenceText = cleanString(referenceText);

    const speechConfig = sdk.SpeechConfig.fromAuthorizationToken(
      speechToken.token,
      speechToken.region
    );

    speechConfig.speechRecognitionLanguage =
      localStorage.getItem("user-locale") ?? "en-US";
    this.speechConfig = speechConfig;

    const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();

    const pronunciationAssessmentConfig = new sdk.PronunciationAssessmentConfig(
      cleanString(referenceText),
      sdk.PronunciationAssessmentGradingSystem.HundredMark,
      sdk.PronunciationAssessmentGranularity.Phoneme,
      true
    );

    pronunciationAssessmentConfig.enableProsodyAssessment = true;

    // pronunciationAssessmentConfig.phonemeAlphabet = "IPA";
    pronunciationAssessmentConfig.phonemeAlphabet = "sapi";

    const recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
    pronunciationAssessmentConfig.applyTo(recognizer);

    recognizer.sessionStopped = function () {
      recognizer.stopContinuousRecognitionAsync();
      recognizer.close();

      // self.calculateOverallPronunciationScore();
      onSessionStopped();
    };

    recognizer.canceled = function (_, e) {
      if (e.reason === sdk.CancellationReason.Error) {
        const str =
          "(cancel) Reason: " +
          sdk.CancellationReason[e.reason] +
          ": " +
          e.errorDetails;
        console.log("Recognizer canceled", str);
      }
      recognizer.stopContinuousRecognitionAsync();
      onCancelled();
    };

    recognizer.recognizing = (_, e: any) => {
      onRecognizing(e);
      console.log("API recognizing results", e.result.text);
      self.recognizingText = e.result.text;
      self.recognizedWordsBeforeAnalysis = e.result.text
        .split(" ")
        .toLowerCase();
    };

    recognizer.recognized = (_, e) => {
      self.jo = JSON.parse(
        e.result.properties.getProperty(
          sdk.PropertyId.SpeechServiceResponse_JsonResult
        )
      );

      const nb = self.jo["NBest"][0];
      console.log("API recognized results", nb);
      self.pronunciationAssessment = nb.PronunciationAssessment;
      self.startOffset = nb.Words[0].Offset;
      const localtext = lodash.map(nb.Words, (item) => item.Word.toLowerCase());
      self.currentText = self.currentText.concat(localtext);
      self.fluencyScores.push(nb.PronunciationAssessment.FluencyScore);
      self.prosodyScores.push(nb.PronunciationAssessment.ProsodyScore);
      const isSucceeded = self.jo.RecognitionStatus === "Success";
      const nBestWords = self.jo.NBest[0].Words;
      self.currentSpeechResult = nBestWords;

      const durationList: any = [];
      lodash.forEach(nBestWords, (word) => {
        if (!self) return;
        self.recognizedWords.push(word);
        durationList.push(word.Duration);
      });
      self.durations.push(lodash.sum(durationList));

      if (isSucceeded && nBestWords) {
        self.allWords.push(...nBestWords);
      }
      self.lastWords = nBestWords;
      // self.calculateOverallPronunciationScore();
      onRecognized(e);
    };

    self.recognizer = recognizer;
  }

  speechConfig: any;
  pronunciationAssessment: any;
  recognizer: any;
  recognizedWordsBeforeAnalysis: any = [];
  recognizingText: string = "";
  scoreNumber: any = {
    accuracyScore: 0,
    fluencyScore: 0,
    compScore: 0,
    prosodyScore: 0,
  };
  allWords: any = [];
  currentText: any = [];
  startOffset = 0;
  recognizedWords: any = [];
  fluencyScores: any = [];
  prosodyScores: any = [];
  durations: any = [];
  jo: any = {};
  language = "en-AU";
  lastWords: any = [];
  lastWord: string = "";
  currentSpeechResult: any = [];
  referenceText = "";

  updateSpeechToken(newSpeechToken: string) {
    this.speechConfig.setProperty(
      sdk.PropertyId.SpeechServiceAuthorization_Token,
      newSpeechToken
    );
  }

  reset() {
    this.scoreNumber = {
      accuracyScore: 0,
      fluencyScore: 0,
      compScore: 0,
      prosodyScore: 0,
    };
    this.allWords = [];
    this.currentText = [];
    this.startOffset = 0;
    this.recognizedWords = [];
    this.fluencyScores = [];
    this.prosodyScores = [];
    this.durations = [];
    this.jo = {};
    this.lastWords = [];
    this.lastWord = "";
  }

  close() {
    console.log("Closed mic");
    if (!this.recognizer) return;
    this.recognizer.stopContinuousRecognitionAsync(() => {
      this.recognizer?.close();
      this.recognizer = null;
    });
  }

  // calculateOverallPronunciationScore() {
  //   const resText = this.currentText.join(" ");
  //   let wholelyricsArry: any = [];
  //   let resTextArray = [];

  //   const resTextProcessed = (resText.toLocaleLowerCase() ?? "")
  //     .replace(new RegExp('[!"#$%&()*+,-./:;<=>?@[^_`{|}~]+', "g"), "")
  //     .replace(new RegExp("]+", "g"), "");
  //   const wholelyrics = (this.referenceText.toLocaleLowerCase() ?? "")
  //     .replace(new RegExp('[!"#$%&()*+,-./:;<=>?@[^_`{|}~]+', "g"), "")
  //     .replace(new RegExp("]+", "g"), "");
  //   this.lastWord = resTextProcessed.split(" ").pop();
  //   wholelyricsArry = wholelyrics.split(" ");
  //   resTextArray = resTextProcessed.split(" ");

  //   const wholelyricsArryRes = lodash.map(
  //     lodash.filter(wholelyricsArry, (item: any) => !!item),
  //     (item: any) => item.trim()
  //   );

  //   function getDifferences(arr1: any[], arr2: any[]) {
  //     const differences = [];
  //     let i = 0,
  //       j = 0;
  //     while (i < arr1.length && j < arr2.length) {
  //       if (arr1[i] === arr2[j]) {
  //         differences.push(["equal", i, i + 1, j, j + 1]);
  //         i++;
  //         j++;
  //       } else if (arr1.slice(i, i + 1).indexOf(arr2[j]) === -1) {
  //         differences.push(["delete", i, i + 1, j, j]);
  //         i++;
  //       } else if (arr2.slice(j, j + 1).indexOf(arr1[i]) === -1) {
  //         differences.push(["insert", i, i, j, j + 1]);
  //         j++;
  //       } else {
  //         differences.push(["replace", i, i + 1, j, j + 1]);
  //         i++;
  //         j++;
  //       }
  //     }
  //     while (i < arr1.length) {
  //       differences.push(["delete", i, i + 1, j, j]);
  //       i++;
  //     }
  //     while (j < arr2.length) {
  //       differences.push(["insert", i, i, j, j + 1]);
  //       j++;
  //     }
  //     return differences;
  //   }

  //   const diff: any = getDifferences(wholelyricsArryRes, resTextArray);

  //   this.lastWords = [];

  //   for (const d of diff) {
  //     if (d[0] == "insert" || d[0] == "replace") {
  //       for (let j = d[3]; j < d[4]; j++) {
  //         if (
  //           this.allWords &&
  //           this.allWords.length > 0 &&
  //           this.allWords[j].PronunciationAssessment.ErrorType !== "Insertion"
  //         ) {
  //           this.allWords[j].PronunciationAssessment.ErrorType = "Insertion";
  //         }
  //         this.lastWords.push(this.allWords[j]);
  //       }
  //     }
  //     if (d[0] == "delete" || d[0] == "replace") {
  //       if (
  //         d[2] == wholelyricsArryRes.length &&
  //         !(
  //           this.jo.RecognitionStatus == "Success" ||
  //           this.jo.RecognitionStatus == "Failed"
  //         )
  //       )
  //         continue;
  //       for (let i = d[1]; i < d[2]; i++) {
  //         const word = {
  //           Word: wholelyricsArryRes[i],
  //           PronunciationAssessment: {
  //             ErrorType: "Omission",
  //           },
  //         };
  //         this.lastWords.push(word);
  //       }
  //     }
  //     if (d[0] == "equal") {
  //       for (let k = d[3], count = 0; k < d[4]; count++) {
  //         if (["zh-cn"].includes(this.language.toLowerCase())) {
  //           let len = 0;
  //           let bfind = false;
  //           lodash.map(this.allWords, (item, index) => {
  //             if (len >= k && !bfind) {
  //               if (
  //                 this.allWords[index].PronunciationAssessment.ErrorType !==
  //                 "None"
  //               ) {
  //                 this.allWords[index].PronunciationAssessment.ErrorType =
  //                   "None";
  //               }
  //               this.lastWords.push(this.allWords[index]);
  //               bfind = true;
  //               k += this.allWords[index].Word.length;
  //             }
  //             len = len + item.Word.length;
  //           });
  //         } else {
  //           this.lastWords.push(this.allWords[k]);
  //           k++;
  //         }
  //       }
  //     }
  //   }

  //   let reference_words = [];
  //   reference_words = wholelyricsArryRes;

  //   const recognizedWordsRes = [];
  //   lodash.forEach(this.recognizedWords, (word) => {
  //     if (word.PronunciationAssessment.ErrorType == "None") {
  //       recognizedWordsRes.push(word);
  //     }
  //   });

  //   let compScore = Number(
  //     ((recognizedWordsRes.length / reference_words.length) * 100).toFixed(0)
  //   );
  //   if (compScore > 100) {
  //     compScore = 100;
  //   }
  //   this.scoreNumber.compScore = compScore;

  //   const accuracyScores: any = [];
  //   lodash.forEach(this.lastWords, (word) => {
  //     if (word && word?.PronunciationAssessment?.ErrorType != "Insertion") {
  //       accuracyScores.push(
  //         Number(word?.PronunciationAssessment.AccuracyScore ?? 0)
  //       );
  //     }
  //   });
  //   this.scoreNumber.accuracyScore = Number(
  //     (lodash.sum(accuracyScores) / accuracyScores.length).toFixed(0)
  //   );

  //   if (this.startOffset) {
  //     const sumRes: any = [];
  //     lodash.forEach(this.fluencyScores, (x, index) => {
  //       sumRes.push(x * this.durations[index]);
  //     });
  //     this.scoreNumber.fluencyScore =
  //       lodash.sum(sumRes) / lodash.sum(this.durations);
  //   }
  //   this.scoreNumber.prosodyScore = Number(
  //     (lodash.sum(this.prosodyScores) / this.prosodyScores.length).toFixed(0)
  //   );

  //   const num = this.scoreNumber;
  //   const sortScore = Object.keys(num).sort(function (a: any, b: any) {
  //     return num[a] - num[b];
  //   });
  //   if (
  //     this.jo.RecognitionStatus == "Success" ||
  //     this.jo.RecognitionStatus == "Failed"
  //   ) {
  //     this.scoreNumber.pronScore = Number(
  //       (
  //         this.scoreNumber[sortScore["0"]] * 0.4 +
  //         this.scoreNumber[sortScore["1"]] * 0.2 +
  //         this.scoreNumber[sortScore["2"]] * 0.2 +
  //         this.scoreNumber[sortScore["3"]] * 0.2
  //       ).toFixed(0)
  //     );
  //   } else {
  //     this.scoreNumber.pronScore = Number(
  //       (
  //         this.scoreNumber.accuracyScore * 0.5 +
  //         this.scoreNumber.fluencyScore * 0.5
  //       ).toFixed(0)
  //     );
  //   }
  // }
}
