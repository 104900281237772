import { useSpeech } from "../../context/SpeechContext";

const PhraseVisualizer = ({
  lessonPhrase,
  noWordDecoration,
  center,
  width,
  type,
}: any) => {
  const { nextWordIndex, currentPhrase } = useSpeech();

  const phrase = lessonPhrase || currentPhrase;

  const currentPhraseWordsVisualization = phrase
    .split(" ")
    .map((word: string, index: number) => {
      return {
        word,
        wasSpoken:
          index <= nextWordIndex &&
          index !== lessonPhrase.split(" ").length - 1,
        index,
      };
    });

  return (
    <div
      className={`flex flex-col ${center ? "items-center" : "items-start"}
      ${width ? `w-[${width}px]` : "w-full"}
      justify-center text-semi-black`}
    >
      <div className="flex flex-wrap items-center justify-start">
        {currentPhraseWordsVisualization &&
          currentPhraseWordsVisualization.length > 0 &&
          currentPhraseWordsVisualization.map(
            (wordObject: any, index: number) => {
              return (
                <span
                  style={{
                    color:
                      wordObject.wasSpoken && !noWordDecoration
                        ? "#929292"
                        : "",
                  }}
                  key={wordObject.word + index}
                  className={`
                  ${type === "assessment" ? "text-[26px]" : "text-3xl"}
                  mb-2 font-normal`}
                >
                  {!noWordDecoration && <span>&nbsp;</span>}
                  {wordObject.word}
                </span>
              );
            }
          )}
      </div>
    </div>
  );
};

export default PhraseVisualizer;
