function Window({
  children,
  className,
  noPadding,
  gradient,
}: {
  children: React.ReactNode;
  className?: string;
  noPadding?: boolean;
  gradient?: string;
}) {
  return (
    <div
      className={`${
        !noPadding ? "px-4 pt-16 pb-12" : ""
      } z-10 flex flex-col rounded-[24px] border-t-[2px] border-r-[2px] border-b-[16px] border-l-[2px] border-[#3800D7] ${
        gradient ? gradient : "bg-gradient-to-b from-[#6027FF] to-[#6D09FC]"
      } drop-shadow-2xl ${className}`}
    >
      {children}
    </div>
  );
}

export default Window;
