import Window from "../../components/Window";
import useSpeechErrors from "../../hooks/useSpeechErrors";
import Tooltip from "../Tooltip";

function FluencyBreakdown({ words }: any) {
  const { isMispronunciationError, isUnexpectedBreakError, isMonotoneError } =
    useSpeechErrors();

  return (
    <Window
      noPadding
      className="p-8 bg-white-gradient border-semi-white w-[832px] h-[80%] justify-center items-center"
    >
      <div className="w-full flex flex-col items-center">
        <h1 className="font-bold text-[32px] pb-8">Fluency Breakdown</h1>
        <div className="text-xl	font-medium leading-[64px] flex flex-wrap">
          {words.map((item: any, index: number) => {
            return (
              <span key={`fluencyBreakdownSpan-${index}`} className="flex">
                &nbsp;
                {displayByErrorType({
                  item,
                  isMispronunciationError,
                  isUnexpectedBreakError,
                  isMonotoneError,
                })}
              </span>
            );
          })}
        </div>
      </div>
    </Window>
  );
}

export default FluencyBreakdown;

function displayByErrorType({
  item,
  isMispronunciationError,
  isUnexpectedBreakError,
  isMonotoneError,
}: any) {
  const hasOmissionError =
    item.PronunciationAssessment?.ErrorType === "Omission";
  const hasInsertionError =
    item.PronunciationAssessment?.ErrorType === "Insertion";

  if (hasInsertionError) {
    return (
      <Tooltip smallText label="Insertion">
        <span className="text-highlight-yellow">{`>${item.Word}<`}</span>
      </Tooltip>
    );
  }

  if (hasOmissionError) {
    return (
      <Tooltip smallText label="Omission">
        <span className="text-highlight-yellow line-through decoration-1">
          {item.Word}
        </span>
      </Tooltip>
    );
  }

  if (isMispronunciationError(item) && isUnexpectedBreakError(item)) {
    return (
      <>
        <Tooltip smallText label="Unexpected Break">
          <span>
            <span className="text-highlight-yellow">____</span>
          </span>
        </Tooltip>
        &nbsp;
        <Tooltip smallText label="Mispronunciation">
          <span className="text-highlight-yellow">{item.Word}</span>
        </Tooltip>
      </>
    );
  }

  if (isMispronunciationError(item)) {
    return (
      <Tooltip smallText label="Mispronunciation">
        <span className="text-highlight-yellow">{item.Word}</span>
      </Tooltip>
    );
  }

  if (isMonotoneError(item) && isUnexpectedBreakError(item)) {
    return (
      <>
        <Tooltip smallText label="Unexpected Break">
          <span>
            <span className="text-highlight-yellow">____</span>
          </span>
        </Tooltip>
        &nbsp;
        <Tooltip smallText label="Monotone">
          <span className="text-highlight-yellow underline decoration-2">
            {item.Word}
          </span>
        </Tooltip>
      </>
    );
  }

  if (isMonotoneError(item)) {
    return (
      <Tooltip smallText label="Monotone">
        <span className="text-highlight-yellow underline decoration-2">
          {item.Word}
        </span>
      </Tooltip>
    );
  }

  if (isUnexpectedBreakError(item)) {
    return (
      <Tooltip smallText label="Unexpected Break">
        <span>
          <span className="text-highlight-yellow">____</span> {item.Word}
        </span>
      </Tooltip>
    );
  }

  return item.Word;
}
