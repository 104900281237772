import { useEffect, useState } from "react";
import { useSpeech } from "../../context/SpeechContext";
import {
  IIntervention,
  InterventionStatus,
  useInterventions,
} from "../../context/InterventionsContext/InterventionsContext";
import checkCheckPng from "../../assets/check-check.png";
import { RichAvatar } from "../Avatar/RichAvatar";
import { useAvatar } from "alpha-ai-avatar-sdk-react";
import { useConversation } from "../../hooks/useConversation";
import Window from "../Window";
import StepsProgressBar from "../StepsProgressBar";
import MicButton from "../MicButton";
import { useLesson } from "../../context/LessonContext";
import phrasesMock from "../../mock/phrases";

export enum RepeatWordInterventionMode {
  WORD = "word",
  PHONEME = "phoneme",
  SYLLABLES = "syllables",
  GRAPHEME = "grapheme",
}

function RepeatWordIntervention({
  id,
  type = RepeatWordInterventionMode.WORD,
}: {
  id: string;
  type?: RepeatWordInterventionMode;
}) {
  const totalLessons = phrasesMock.length - 1;
  const [freezeTopAvatar, setFreezeTopAvatar] = useState(false);
  const { interventions, setInterventions } = useInterventions();
  const [showCompletedMessage, setShowCompletedMessage] = useState(false);
  const [interventionLoaded, setInterventionLoaded] = useState(false);
  const [useMouthZoom, setMouthZoom] = useState(false);

  const { currentSpeechResult } = useSpeech();
  const { lessonStep, blockUserSpeech, setBlockUserSpeech } = useLesson();
  const currentIntervention = interventions.find(
    (intervention: IIntervention) => {
      return intervention.id === id;
    }
  )!;

  const updateInterventions = () => {
    const updatedInterventions = interventions.map((intervention: any) => {
      if (intervention.id === id) {
        return {
          ...intervention,
          status: InterventionStatus.COMPLETED,
        };
      }
      return intervention;
    });
    setInterventions([...updatedInterventions]);
  };

  useEffect(() => {
    if (!interventionLoaded) {
      setInterventionLoaded(true);
      return;
    }

    const gotWordRightAtLeastOnce =
      currentSpeechResult.length > 0 &&
      currentSpeechResult.some(
        (result: any) =>
          result.PronunciationAssessment.ErrorType !== "Mispronunciation" &&
          result.PronunciationAssessment.AccuracyScore >= 80
      );

    if (gotWordRightAtLeastOnce) {
      setShowCompletedMessage(true);
      // setTempFlag(false);
      setMouthZoom(false);
      cancelConversation();
      setFreezeTopAvatar(false);
      say("Nice work!");
      setTimeout(() => {
        onFinished();
      }, 2000);
    } else {
      if (!blockUserSpeech) {
        say(`Not quite. Let's try again`);
      }
    }
  }, [currentSpeechResult]);

  const { word, phonemes, graphemes, syllables } = currentIntervention;

  const phrase = () => {
    switch (type) {
      case RepeatWordInterventionMode.PHONEME:
        return phonemes;
      case RepeatWordInterventionMode.GRAPHEME:
        return graphemes;
      case RepeatWordInterventionMode.SYLLABLES:
        return syllables;
      default:
        return word;
    }
  };

  const { say } = useAvatar();
  const avatarMessage = phrase();
  const { cancelConversation } = useConversation(
    {
      queue: [
        () => {
          setBlockUserSpeech(true);
          setFreezeTopAvatar(true);
          say(`Let's work on the word ${phrase()}. Watch my lips`, {
            prosody: { rate: "-10%" },
          });
        },
        () => {
          setMouthZoom(true);
          // prosody rate caps at -50% (@TODO can we do something to make it even slower?)
          say(avatarMessage, { prosody: { rate: "-100%" } });
        },
        () => {
          setMouthZoom(false);

          say(`${phrase()}! Now you say the word`, {
            prosody: { rate: "-10%" },
          });
        },
        () => {
          setBlockUserSpeech(false);
        },
      ],
    },
    currentIntervention.word
  );

  const onFinished = () => {
    setShowCompletedMessage(false);
    updateInterventions();
  };

  return (
    <div className="flex flex-col">
      <Window noPadding className="w-[690px] py-6 px-8 mt-4">
        <div className="flex">
          <div className="mr-8 w-[125px] h-[129px] border-t-[2px] border-r-[2px] border-b-[16px] border-l-[2px] border-[#3800D7] rounded-full">
            <RichAvatar
              size="120"
              freeze={freezeTopAvatar}
              message={avatarMessage}
              useMouthZoom={useMouthZoom}
            />
          </div>
          <div className="w-full flex flex-col">
            <StepsProgressBar
              steps={totalLessons + 1}
              currentStep={lessonStep}
            />

            <div className="flex justify-between align-center h-full">
              <div className="self-center h-[48px] flex justify-center items-center min-w-[136px] text-white text-xl font-bold rounded-xl border border-white/90 bg-white/5">
                <span className="">Practice</span>
              </div>
              <MicButton
                blockUserSpeech={blockUserSpeech}
                micButtonIsEnabled={true}
              />
            </div>
          </div>
        </div>
      </Window>
      <Window
        className={`mt-4 w-[690px] h-full justify-center items-center ${
          showCompletedMessage
            ? "bg-green-gradient border-dark-green"
            : "bg-white-gradient border-semi-white"
        }`}
      >
        <div className="flex items-center justify-center flex-col items-center w-full">
          {!showCompletedMessage ? (
            <>
              <div className="text-center font-semibold text-6xl mb-4">
                {word}
              </div>
              <RichAvatar
                size="160"
                message={avatarMessage}
                useMouthZoom={useMouthZoom}
              />
            </>
          ) : (
            <div className="pb-4 flex justify-center items-center flex-col min-h-[50%] text-white">
              <div className="font-semibold text-2xl mt-10 mb-2">
                Great Job!
              </div>
              <div className="font-semibold text-6xl mb-4">{word}</div>
              <img src={checkCheckPng} />
            </div>
          )}
        </div>
      </Window>
    </div>
  );
}

export default RepeatWordIntervention;
