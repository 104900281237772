import React from "react";

type StepProgressBarProps = {
  steps: number;
  currentStep: number;
  className?: string;
};

function getBorderProperties(index: number, steps: number) {
  if (index === 0) {
    return "rounded-l-full";
  }

  if (index === steps - 1) {
    return "rounded-r-full border-black border-l";
  }

  return "border-black border-l";
}

const StepProgressBar: React.FC<StepProgressBarProps> = ({
  steps,
  currentStep,
  className,
}) => {
  const progressWidth = (currentStep / steps) * 100;

  return (
    <div
      className={`h-[35px] relative w-full h-5 rounded-full bg-blue-700 z-10 ${className}`}
    >
      <div className="sombra"></div>
      <div className="absolute inset-0 bg-white rounded-full flex overflow-hidden shadow-top-inner">
        <div
          className="transition-all duration-300 z-20 h-[21px] mt-[4px] ml-[10px] rounded-l-[20px]"
          style={{
            width: `${
              currentStep > 0
                ? `calc(${progressWidth}% - 9px)`
                : `${progressWidth}%`
            }`,
            background: "linear-gradient(180deg, #6027FF 0%, #6D09FC 100%)",
          }}
        ></div>
      </div>
      <div className="my-[4px] absolute inset-0 flex justify-between z-10">
        {Array.from({ length: steps }, (_, index) => (
          <div
            key={index}
            className={`h-full ${getBorderProperties(index, steps)}`}
            style={{ width: `${100 / steps}%` }}
          />
        ))}
      </div>
    </div>
  );
};

export default StepProgressBar;
